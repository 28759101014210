<template>
    <div>
        <div v-if="birthdays.length > 0" class="mt-3">
            <div class="login-card">
                <div class="card-header ml-2 mt-2">
                    Ближайшие дни рожденья
                </div>
                <div class="card-body">
                    <div class="row row-cols-2 mt-2">
                        <div v-for="(user, index) in birthdays" :key="index" class="col mb-4">
                            <div class="align-items-center text-center">
                                <a :href="user.url">
                                    <img
                                        :src="user.avatar"
                                        width="100" height="100" class="rounded-circle mt-2 mb-2">
                                </a>
                                <div class="text-center">
                                    <p> {{user.name}} </p>
                                    <p> {{user.date}} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                birthdays: [],
            }
        },
        name: "Birthdays",
        mounted() {
            this.update()
        },
        methods: {
            update() {
                axios.get(`/vue/getBirthdays`)
                    .then((response) => this.birthdays = response.data)
            },

        }
    }

</script>

<style scoped>
    .card-header {
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        border-bottom: 0px;
    }
    .rounded-circle{
        border: none !important;
        padding: 1px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
</style>
