<template>
    <div>
        <h4 v-if="replies.length != 0" class="mt-5 mb-5 comtitle">Комментарии</h4>
        <transition-group name="fade">
                <div v-for="(reply,index) in replies" :key="index + 0" class="login-card mb-2 combody">
                        <div class="row ml-2 justify-content-between m30">
                            <a class="nodec name" :href="reply.url">
                                <img :src="reply.avatar"
                                     width="30" height="30" class="rounded-circle">
                                <b>{{ reply.User}}</b>
                            </a>
                            <form v-if="reply.deletable" @submit.prevent="delReply(reply.id)">
                                <button class="btn fa fa-times" style="color: red; padding-right: 0px;"></button>
                            </form>
                        </div>
                    <div>
                        <hr class="ml30">
                        <p class="m30">{{reply.body}}</p>
                        <div class="m30 d-flex justify-space-between">
                        <p class="float-left"><i>{{ reply.time }}</i></p>
                            <div class="mr-4"><like-reply :id="reply.id"></like-reply></div>
                        </div>
                    </div>
                </div>
        </transition-group>
        <h4 class="comtitle mt-4 mb-5">Добавить комментарий</h4>
        <div class="login-card combody">
            <form class="mb-3 ma30" @submit.prevent="addReply">
<!--            <textarea v-model="reply" @click="addRow" class="form-control" name="reply" id="" cols="30"-->
<!--                      :rows="rows"></textarea>-->
                <VueEmoji ref="emoji" @input="onInput" width="100%" height="100"/>
                <button class="btn btn-primary mt-2">Добавить комментарий</button>
            </form>
        </div>

    </div>

</template>

<script>
    import {eventEmitter} from "../app";
    import LikeReply from "./LikeReply";
    import VueEmoji from 'emoji-vue'
    export default {
        components: {LikeReply, VueEmoji},
        data: function () {
            return {
                replies: [],
                action: `/news/replies/${this.id}?vue=newsrep`,
                delAction: `/news/reply/${this.id}`,
                rows: 1,
                reply: '',
                height: '50'
            }
        },
        name: "CommentsNews",
        props: ['id'],
        mounted() {
            this.update()
            setTimeout(function () {
                let a = document.querySelector('.emoji-wysiwyg-editor')
                a.focus()
            }, 2000)
        },
        methods: {
            onInput(e){
                this.reply = e.data
            },
            update() {
                axios.get(this.action).then(
                    (response) => {
                        this.replies = response.data;
                    }
                )
            },
            addRow() {
                this.height = '100'
            },
            addReply() {
                let data = new FormData();
                data.append('reply', this.reply);
                axios.post(`${this.delAction}`, data)
                    .then( () => {
                        this.update()
                        eventEmitter.$emit('newscomment')
                        this.rows = 1
                        this.reply = ''
                        this.$refs.emoji.clear()
                    }
                )
                    .catch((error) => {
                        alert('Что то пошло не так');
                    }
                )
            },
            delReply(id) {
                axios.delete(`/news/reply/${id}`).then(() => {
                    eventEmitter.$emit('newscomment')
                    this.update()
                })

            }
        }
    }

</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }
    .comtitle{
        color: black;
        font-family: 'Roboto', Helvetica, Arial, serif;
        font-size: 23.0px;
        font-style: normal;
        font-weight: 500;
    }
    .login-card{
        border-radius: 8px !important;
    }
    .m30{
        margin: 30px 30px 12px 30px !important;
    }
    .ml30{
        margin: 0px 30px !important;
    }
    .name{
        color: black !important;
        font-size: 15.0px;
    }
    .ma30{
        margin: 30px !important;
    }
    /*.combody{*/
    /*    width: 70%;*/
    /*}*/
    /*@media (max-width: 700px) {*/
    /*    .combody{*/
    /*        width: 100%;*/
    /*    }*/
    /*}*/

</style>
