<template>
    <div class="mt-3">
        <button class="btn btn-primary" id="pick-avatar">{{text}}</button>
        <avatar-cropper
            @uploaded="handleUploaded"
            trigger="#pick-avatar"
            :cropper-options="{
                aspectRatio: 1,
                autoCropArea: 1,
                viewMode: 1,
                movable: true,
                zoomable: true
            }"
            :output-quality="1"
            :output-options="{width: 300, height: 300}"
            :upload-form-data="{'_token': csrf}"
            :labels="{submit: 'Загрузить', cancel: 'Отмена'}"
            :upload-url="`/profile/upload-avatar/${id}`"/>
    </div>
</template>

<script>
    import AvatarCropper from "vue-avatar-cropper"
    import {eventEmitter} from "../../../app";
    export default {
        components: {AvatarCropper},
        data: () => {
            return {
                userAvatar: undefined,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        },
        props: ['id', 'text'],
        methods: {
            handleUploaded() {
                eventEmitter.$emit('avatarUploaded')
                alert('Аватар загружен!')
            },
        }
    }
</script>
