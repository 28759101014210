export default {
    state: {
        permissions: []
    },
    mutations: {
        updatepermissions(state, permissions){
            state.permissions = permissions
        }
    },
    actions: {
        getpermissions({commit}) {
            axios.get('/vue/getPermissions')
                .then(res => {
                    let permissions = res.data
                    commit('updatepermissions',permissions)
                })

        },
   },
    getters: {
        allPermissions(state){
            return state.permissions
        },
        getPermission: (state) => (permission) =>{
            for (let item of state.permissions){
                if(item.name === permission){
                    return true;
                }
            }
        },

    }
}
