<template>
    <div>
        <div class="row ml-10 mr-10 justify-content-between">
            <h1>Темы обращений</h1>
            <button title="Добавить" @click="dialog = true; noedit();" type="submit" class="btn btn-primary ml-3"><i
                class="fa fa-plus"></i></button>
        </div>
        <div class="container login-card login-card-8">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Название</th>
                    <th scope="col">SLA</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(topic,index) in topics" :key="index">
                    <th scope="row">{{topic.id}}</th>
                    <td>{{topic.title}}</td>
                    <td>{{topic.SLA}}</td>
                    <td>
                        <i @click="dialog = true; edittopic(topic);" class="fa fa-pencil btn btn-success"></i>
                        <i @click="deletetopic(topic.id)" class="fa fa-times btn btn-danger"></i>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li @click.prevent="gettopics(pagination.prev_page_url)"
                        :class="{disabled: !pagination.prev_page_url}"
                        class="page-item">
                        <a class="page-link" href="#">Назад</a>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                            {{pagination.last_page}}</a>
                    </li>
                    <li class="page-item"
                        @click.prevent="gettopics(pagination.next_page_url)"
                        :class="{disabled: !pagination.next_page_url}">
                        <a
                            class="page-link" href="#">Следующая</a>
                    </li>
                </ul>
            </nav>

            <v-dialog
                light
                v-model="dialog"
                max-width="800px"
            >
                <form @submit.prevent="addtopic">
                    <div class="form-row">
                        <div class="col">
                            <input v-model="topic.title" name="title" id="titlech" type="text" class="form-control"
                                   placeholder="Тема обращения">
                        </div>
                        <div class="col">
                            <input v-model="topic.SLA" name="SLA" id="SLA" type="text" class="form-control"
                                   placeholder="SLA в часах">
                        </div>
                        <div class="col">
                            <button v-if="edit" type="submit" class="btn btn-success">Изменить</button>
                            <button v-else type="submit" class="btn btn-success">Добавить</button>
                        </div>
                    </div>
                </form>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "topics",
        data() {
            return {
                topics: [],
                topic: {
                    id: '',
                    title: '',
                    SLA: ''
                },
                dialog: false,
                edit: false,
                pagination: {},
            }
        },
        methods: {
            gettopics(page_url) {
                if (!page_url){
                    page_url = '/api/topics?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }else{
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get(page_url)
                    .then(res => {
                        this.topics = res.data.data
                        this.makePagination(res.data)
                    })
            },
            deletetopic(id) {
                axios
                    .delete(`/api/topics/${id}`,{headers: {'Authorization' : 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP' }})
                .then(()=> {
                    this.gettopics()
                })
            },
            addtopic() {
                if (this.edit === false) {
                    axios
                        .post(`/api/topics`, {
                            title: this.topic.title,
                            SLA: this.topic.SLA
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.topic.title = ''
                            this.topic.SLA = ''
                            this.dialog = false
                            this.gettopics()
                        })
                } else {
                    axios
                        .put(`/api/topics/${this.topic.id}`, {
                            title: this.topic.title,
                            SLA: this.topic.SLA
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.topic.id = ''
                            this.topic.title = ''
                            this.topic.SLA = ''
                            this.edit = false
                            this.dialog = false
                            this.gettopics()
                        })
                }

            },
            edittopic(topic) {
                this.edit = true
                this.topic.id = topic.id;
                this.topic.title = topic.title;
                this.topic.SLA = topic.SLA;
            },
            noedit() {
                this.edit = false
                this.topic.id = ''
                this.topic.title = ''
                this.topic.SLA = ''
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
        },
        mounted() {
            this.gettopics()
        }
    }
</script>

<style scoped>
    .form-row {
        background: #fff;
        padding: 10px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }
</style>
