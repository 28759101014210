export default {
    state: {
        users: [],
        best: []
    },
    mutations: {
        updateuser(state, users){
            state.users = users
        },
        updateBest(state, best){
            state.best = best
        }
    },
    actions: {
        getusers({commit}) {
            axios.get('/api/users?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP&nopaginate=1')
                .then(res => {
                    let users = res.data
                    commit('updateuser',users)
                })

        },
        getBest({commit}){
            axios.get('/api/bests?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                .then(res => commit('updateBest', res.data))
        }
    },
    getters: {
        ACTIVE_USERS(state){
            return state.users.filter(item =>item.active = 1)
        },
        BEST_USERS(state){
            return state.best
        }


    }
}
