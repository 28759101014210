import Vue from 'vue'
import Vuex from 'vuex'
import permissions from "./modules/permissions";
import users from "./modules/users";
import locations from "./modules/locations";
import videos from "./modules/videos";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        permissions, users, locations, videos
    }
})

export default store;
