<template>
    <div class="container">
        <transition-group name="fade">
        <div v-for="(reply,index) in replies" :key="index + 0" class="login-card mb-2 combody">
                <div class="row ml-2 justify-content-between m30">
                    <a class="nodec name" :href="reply.url">
                        <img :src="reply.avatar"
                             width="30" height="30" class="rounded-circle">
                        <b>{{ reply.User}}</b>
                    </a>
                </div>
                <div>
                    <hr class="hr mt-4">
                    <p class="m30">{{reply.body}}</p>
                    <p class="float-right mr-4"><i>{{ reply.time }}</i></p>
                </div>
        </div>
        </transition-group>
        <h4>Добавить ответ</h4>
        <div class="login-card combody">
        <form @submit.prevent="addReply" class="mb-2 ma30">
            <textarea v-model="reply" @click="addRow" class="form-control" name="reply" id="" cols="30" :rows="rows"></textarea>
            <button class="btn btn-primary mt-2">Отправить ответ</button>
        </form>
        </div>
    </div>

</template>

<script>
    import { eventEmitter } from "../app";

    export default {
        data: function () {
            return {
                replies: [],
                id: this.$route.params['id'],
                action: ``,
                rows: 1,
                reply: '',
               }
        },
        name: "replyIssue",
        // props: ['id'],
        mounted() {
            this.update()
        },
        methods: {
            update() {
                axios.get(`/issue/replies/${this.id}`).then(
                    (response) => {
                        this.replies = response.data;
                    }
                )
            },
            addRow(){
                this.rows = 5
            },
            addReply(){
                let data = new FormData();
                data.append('reply', this.reply);
                axios.post(`/issue/reply/${this.id}`, data)
                    .then(()=> {
                        this.update();
                        eventEmitter.$emit('notification')
                    })
                    .catch((error)=> {
                      alert('Нельзя комментировать чужую запись');
                }
                )
                this.rows = 1;
                this.reply = '';
            }
        }
    }

</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }
    .ma30{
        margin: 30px !important;
    }
    /*.combody{*/
    /*    width: 70%;*/
    /*}*/
    /*@media (max-width: 700px) {*/
    /*    .combody{*/
    /*        width: 100%;*/
    /*    }*/
    /*}*/
    .hr{
        margin: 5px 30px 0px 30px;
    }
    .login-card{
        border-radius: 8px !important;
    }
    .m30{
        margin: 30px 30px 0px 30px !important;
    }
    .name{
        color: black !important;
        font-size: 15.0px;
    }
</style>
