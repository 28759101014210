<template>
    <div>
        <div v-if="status == 'no_exist'">
            <div class="login-card">
                <div class="card-body">
                    <h3>Обращения не существует</h3>
                </div>
            </div>
        </div>
        <div v-if="status == 'no_access'">
            <div class="login-card">
                <div class="card-body">
                    <h3>У вас нет доступа на обращение</h3>
                </div>
            </div>
        </div>

        <div v-if="status == 'ok'" class="container">
            <h2>Обращение номер #{{issue.id}}</h2>
            <div class="login-card mb-2">
                <div class="top">
                    <h3>{{issue.topic}} <a v-if="article.title" :href="article.url"> "{{article.title}}"</a></h3>
                </div>
                <div class="issue_body comdate">
                    <p><b>Автор:</b> <i>{{issue.auth}}</i></p>
                    <p><b>Локация:</b> <i>{{issue.location}}</i></p>
                    <p><b>Создана:</b> <i>{{issue.time}}</i></p>
                    <hr>
                    <p><b>Текст обращения:</b></p>
                    <p>{{issue.body}}</p>
                    <hr>
                    <p><b>Статус:</b> <i>{{issue.status_text}}</i></p>

                    <p v-if="issue.status_id != 3">
                        <b>Крайний срок:</b>
                        <i v-if="issue.clear">{{issue.sla}}</i>
                        <i v-else class="alert-danger">{{issue.sla}}</i>
                    </p>
                </div>

                <div class="row comdate d-flex justify-content-end">
                    <button v-if="issue.status_id == 3" @click="reopenIssue" class="btn btn-primary ml-2">
                        Переоткрыть обращение
                    </button>
                    <button v-if="issue.status_id != 3" @click="closeIssue" class="btn btn-primary ml-2">Закрыть
                        обращение
                    </button>
                </div>
                <hr class="comdate mt-4">
                <h4 class="mt-4 comdate">Скриншоты</h4>
                <div v-if="images.length > 0" class="comdate">
                    <CoolLightBox
                        :items="images"
                        :index="index"
                        @close="index = null">
                    </CoolLightBox>
                    <div class="images-wrapper mt-4">
                        <div class="image-wrapper"
                             v-for="(image, imageIndex) in images"
                             :key="imageIndex">
                            <div
                                class="image"
                                @click="index = imageIndex"
                                :style="{ backgroundImage: 'url(' + image + ')' }"
                            ></div>
                        </div>

                    </div>
                </div>
                <div class="row screen">
                    <button class="btn btn-primary mt-2" @click="dialog = true">Добавить скриншот</button>
                </div>
            </div>
        </div>


        <h2 v-if="replyes.length > 0" class="container">Ответы на обращение</h2>
        <reply-issue/>

        <v-dialog
            v-model="dialog"
            max-width="800px">
            <form @submit.prevent="uploadScreen">
                <validation-errors v-if="validationErrors"
                                   :errors="validationErrors">

                </validation-errors>
                <div class="form-group row">
                    <label for="image" class="col-md-4 col-form-label text-md-right">Скриншот</label>

                    <div class="col-md-6">
                        <input type="file" id="image" name="image" ref="file" @change="handleFileUpload">
                    </div>
                </div>
                <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                        <button type="submit" class="btn btn-primary">
                            Отправить скриншот
                        </button>
                    </div>
                </div>
            </form>
        </v-dialog>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
    import {eventEmitter} from "../../../app";
    import {mapActions, mapGetters} from 'vuex'

    export default {
        components: {
            CoolLightBox,
        },
        name: "singleissue",
        data: function () {
            return {
                id: this.$route.params['id'],
                issue: [],
                replyes: [],
                status: '',
                article: [],
                images: [],
                index: null,
                dialog: false,
                image: '',
                validationErrors: ''
            }
        },
        computed:{
            ...mapGetters(['getLocation'])
        },
        methods: {
            ...mapActions(['getlocations']),
            getIssue() {
                axios.get(`/vue/singleissue/${this.id}`)
                    .then(res => {
                        if (res.data == 'no_exist') {
                            this.status = 'no_exist'
                        } else if (res.data == 'no_access') {
                            this.status = 'no_access'
                        } else {
                            this.status = 'ok'
                            this.issue = res.data.issue
                            this.replyes = res.data.reply
                            if (this.issue.article_id) {
                                axios.get(`/vue/getInfoArt/${this.issue.article_id}`)
                                    .then(res => this.article = res.data)
                            }
                        }
                    })
            },
            getImages() {
                axios.get(`/issue/image/${this.id}`)
                    .then(res => {
                        this.images = res.data;
                    })
            },
            reopenIssue() {
                axios.post(`/issue/reopen/${this.id}`)
                    .then(() => {
                            this.getIssue();
                            eventEmitter.$emit('notification');
                        }
                    )
            },
            closeIssue() {
                axios.post(`/issue/close/${this.id}`)
                    .then(() => {
                            this.getIssue();
                            eventEmitter.$emit('notification');
                        }
                    )
            },
            uploadScreen() {
                let fd = new FormData;
                fd.append('image', this.image)
                fd.append('id', this.id)
                axios
                    .post(`/issue/upload`, fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(() => {
                        this.dialog = false
                        this.getIssue()
                        this.getImages()
                        this.image = ''
                        this.validationErrors = ''
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors
                        }
                    })
            },
            handleFileUpload(e) {
                this.image = e.target.files[0]
            }
        },
        mounted() {
            this.getIssue()
            this.getImages()
            this.getlocations()
        }
    }
</script>

<style scoped>
    .btn-primary {
        color: white !important;
    }

    .btn-primary:hover {
        color: #a7caff;
    }

    a {
        color: grey !important;
        font-weight: bold;
    }

    .images-wrapper {
        display: flex;
    }

    .image-wrapper {
        margin-bottom: 20px;
        width: calc(16.66667% - 20px);
        margin-right: 20px;
    }

    .image {
        cursor: pointer;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 100%;
    }

    form {
        background: #fff;
        padding: 16px;
        margin: 0;
    }

    .top{
        margin: 50px 50px 20px 50px;
    }
    .screen{
        margin: 0px 50px 50px 50px;
    }
    .comdate{
        margin: 0px 50px;
    }

    .login-card{
        border-radius: 8px !important;
    }
</style>
