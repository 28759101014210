<template>
    <div>

        <div class="input-group mb-4">
            <div class="input-group-prepend mb-2">
                <div class="input-group-text" id="inputGroupPrepend"><i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <input v-model="query" @input="queryUser()" id="input" type="text" class="form-control mb-2"
                   aria-describedby="inputGroupPrepend" :placeholder="ph">
        </div>
        <div class="row row-cols-lg-2 d-flex">
            <div class="mediafull" v-for="user in users">
                <div class="login-card mh mr-4 ml-4 mb-4">
                    <div class="card-body">
                        <div class="row">
                            <router-link :to="{ name: 'profile', params: { id: user.id}}" tag="div"
                                         class="top-name-card">
                                {{user.name}}
                            </router-link>
                        </div>
                        <div class="row">
                            <div class="col-4 d-flex align-items-center justify-content-center">
                                <router-link tag="a" :to="{ name: 'profile', params: { id: user.id}}">
                                    <img :src="user.avatar"
                                         class="rounded-circle av"
                                         alt="">
                                </router-link>
                            </div>
                            <div class="col-8 des">
                                <p>Должность: {{user.vacant}}</p>
                                <p>Телефон: <a :href="`callto:${user.phone}`">{{user.phone}}</a></p>
                                <p>Email: <a :href="`mailto:${user.email}`">{{user.email}}</a></p>
                                <div v-if="user.location">
                                    <p>Локация: {{ user.location.title }}</p>
                                    <p>Адрес: {{user.location.city}} {{ user.location.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: "AllUsers",
        data: function () {
            return {
                users: [],
                query: '',
                ph: 'Поиск сотрудника'
            }
        },
        methods: {
            getProfiles() {
                axios.get(`/vue/getProfiles`).then(
                    (response) => {
                        this.users = response.data;
                    }
                )
            },
            queryUser() {
                axios.get(`/vue/queryUsers/${this.query}`).then(
                    (response) => {
                        this.users = response.data;
                    }
                )
            },
            link(url) {
                window.location.href = `/profile/${url}`
            }
        },
        mounted() {
            this.getProfiles()
        }

    }
</script>

<style scoped>
    .form-control {
        height: calc(1.6em + .75rem + 5px);
        box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08), 0px 4px 4px rgba(51, 51, 51, 0.04);
        font-weight: 400;
        font-style: normal;
        font-size: 15.0px;
        color: rgba(17, 17, 17, 0.48);
        text-align: left;
        line-height: 24.0px;
    }

    .mh{
        min-height: 240px;
    }

    .mediafull {
        margin-bottom: 6px;
        margin-top: 6px;
    }

    .top-name-card {
        margin-top: 8px;
        margin-left: 24px;
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .input-group-text {
        background-color: white;
    }

    .av {
        width: 105px;
        height: 105px;
    }

    .name {
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
    }

    .des p {
        font-family: "Roboto", Helvetica, Arial, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 13.0px;
        color: rgba(0, 0, 0, 1.0);
        text-align: left;
        letter-spacing: 0.26px;
        line-height: 12.0px;
    }
    .rounded-circle{
        border: none !important;
        padding: 1px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .card-body {
        padding: 0;
    }
    @media (max-width: 992px){
        .mediafull{
            width: 100%;
        }
    }
</style>
