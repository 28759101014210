<template>
    <div>
        <div v-if="getPermission('issue_change')" class="input-group mb-4">
            <div class="input-group-prepend mb-2">
                <div class="input-group-text" id="inputGroupPrepend"><i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <input v-model="query" @input="queryIssue(query)" id="input" type="text" class="form-control mb-2"
                   aria-describedby="inputGroupPrepend" placeholder="Поиск по заявителю">
        </div>
        <div class="login-card">
            <div class="card-header ml-2 mt-2 d-flex justify-content-between align-items-center">
                <div>
                    <p>Обращения</p>
                    <p v-if="getPermission('issue_change')">Всего: {{counter.all}} | Открытых: {{counter.open}} | Закрытых: {{counter.closed}} </p>
                </div>
                <div>
                    <button @click="closedIssues" class="btn btn-primary">Закрытые</button>
                    <button @click="getIssue" class="btn btn-primary">Открытые</button>
                    <button @click="timeoutIssues" class="btn btn-primary">Просроченные</button>
                </div>
            </div>
            <div class="card-body">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">
                            <input
                                type="text"
                                v-model.lazy="number"
                                @change="queryNumIssue(number)"
                                placeholder="##"
                                class="maxw"
                            >
                        </th>
                        <th scope="col">Заявитель</th>
                        <th scope="col">Тема</th>
                        <th v-if="getPermission('issue_change')" scope="col">Локация</th>
                        <th scope="col">Дата</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Срок</th>
                    </tr>
                    </thead>
                    <tbody>

                    <router-link v-for="(issue, index) in issues" :key="index" tag="tr"
                                 :to="{ name: 'singleissue', params: { id: issue.id}}">
                        <th scope="row">{{issue.id}}</th>
                        <td>{{issue.name}}</td>
                        <td>{{issue.title}}</td>
                        <td v-if="getPermission('issue_change')">{{issue.location}}</td>
                        <td>{{issue.date}}</td>
                        <td>{{issue.status}}</td>
                        <td v-if="!issue.outdated" class="alert-danger">{{issue.SLA}}</td>
                        <td v-else>{{issue.SLA}}</td>
                    </router-link>


                    </tbody>
                </table>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div v-if="getPermission('issue_change')">
                <button class="btn btn-primary mt-4 float-left" @click="downloadIssues">Выгрузить в xls</button>
            </div>
            <div>
            <button class="btn btn-primary mt-4 float-right" @click="dialog = true">Создать обращение</button>
            </div>
        </div>

        <v-dialog
            v-model="dialog"
            max-width="800px">
            <form @submit.prevent="addIssue">
                <validation-errors v-if="validationErrors"
                                   :errors="validationErrors">

                </validation-errors>

                <div class="form-group row">
                    <label for="topic" class="col-md-4 col-form-label text-md-right">Тема обращения</label>

                    <div class="col-md-6">
                        <select v-model="newissue.topic_id" name="topic" id="topic" class="form-control">
                            <option v-for="(topic,index) in topics" v-if="topic.id != 4" :key="index" :value="topic.id">{{topic.title}}
                            </option>
                        </select>

                    </div>
                </div>
                <div class="form-group row">
                    <label for="body" class="col-md-4 col-form-label text-md-right">Текст обращения</label>

                    <div class="col-md-6">
                        <textarea v-model="newissue.body" name="body" id="body" cols="30" rows="10"
                                  class="form-control"></textarea>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="image" class="col-md-4 col-form-label text-md-right">Скриншот</label>

                    <div class="col-md-6">
                        <input type="file" id="image" name="image" ref="file" @change="handleFileUpload">
                    </div>
                </div>
                <div class="form-group row mb-0">
                    <div class="col-md-6 offset-md-4">
                        <button type="submit" class="btn btn-primary">
                            Создать обращение
                        </button>
                    </div>
                </div>
            </form>
        </v-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import {eventEmitter} from "../../../app";

    export default {
        name: "myissues",
        data: function () {
            return {
                issues: [],
                query: '',
                number: '',
                issueChange: false,
                dialog: false,
                validationErrors: '',
                topics: [],
                newissue: {
                    topic_id: 1,
                    body: '',
                    image: ''
                },
                counter: {}
            }
        },
        computed: {
            ...mapGetters(['allPermissions', 'getPermission']),
        }
        ,
        methods: {
            ...mapActions(['getpermissions']),
            getTopics() {
                axios.get('/api/topics?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP&topics=all')
                    .then(res => {
                        this.topics = res.data
                    })
            },
            getIssue() {
                axios.get(`/vue/getMyIssues`).then(
                    (response) => {
                        this.issues = response.data.issues;
                        this.issueChange = response.data.issueChange
                        this.counter = response.data.counter
                    }
                )
            },
            closedIssues() {
                axios.get(`/vue/getMyIssues?state=closed`)
                    .then(res => {
                        this.issues = res.data.issues
                    })
            },
            timeoutIssues() {
                axios.get(`/vue/getMyIssues?state=timeout`)
                    .then(res => {
                        this.issues = res.data.issues
                    })
            },
            queryIssue(query) {
                this.issues = this.issues.filter(item => item.name.includes(query))
                if (query == '') {
                    this.getIssue();
                }
            },
            queryNumIssue(number) {
                this.issues = this.issues.filter(item => item.id == number)
                if (number == '') {
                    this.getIssue();
                }
            },
            addIssue() {
                let fd = new FormData;
                if(this.newissue.image){
                    fd.append('image', this.newissue.image)
                }
                fd.append('body', this.newissue.body)
                fd.append('topic', this.newissue.topic_id)
                axios
                    .post(`/issue/new`, fd, { headers: {
                    'Content-Type': 'multipart/form-data'}})
                    .then(() => {
                        this.newissue.topic_id = 1
                        this.newissue.body = ''
                        this.newissue.image = ''
                        this.validationErrors = ''
                        this.dialog = false
                        this.getIssue()
                        eventEmitter.$emit('notification')
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors
                        }
                    })
            },
            handleFileUpload(e){
                this.newissue.image = e.target.files[0]
            },
            downloadIssues(){
                window.location = `/issue/download`
            }
        },

        mounted() {
            this.getIssue()
            this.getTopics()
            this.getpermissions()
        }

    }
</script>

<style scoped>
    .card-header {
        border-bottom: none;
        font-family: "Roboto", Helvetica, Arial, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
    }

    a {
        color: white !important;
        font-weight: 500;
    }

    .maxw {
        max-width: 20px;
    }

    form {
        background: #fff;
        padding: 16px;
        margin: 0;
    }
</style>
