<template>
    <div class="mt-2">
        <div v-if="show == 1" class="row row-cols-2 row-cols-md-4">
            <div v-for="(user, index) in users" :key="index" class="col mb-4">
                <div class="align-items-center text-center">
                    <a :href="user.url">
                        <img
                            :src="user.avatar"
                            :width="size" height="size" class="rounded-circle mt-2 mb-6">
                    </a>
                    <div class="text-center">
                        <p class="bold"> {{user.name}} </p>
                        <p class="vacant"> {{user.vacant}} </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="show == 0" class="row row-cols-md-8">
            <div v-for="(user, index) in users" :key="index" class="col mb-4">
                <div class="align-items-center text-center">
                    <a :href="user.url">
                        <img
                            :src="user.avatar"
                            :width="size" height="size" class="rounded-circle mt-2 mb-6">
                    </a>
                    <div class="text-center">
                        <p class="bold"> {{user.name}} </p>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {eventEmitter} from "../../app";
    export default {
        data: function () {
            return {
                users: [],
            }
        },
        props: ['size', 'show']
        ,
        name: "BestUsers",
        mounted() {
            this.update()
            eventEmitter.$on('bestchange', count => {
                this.update()
            })
        },
        methods: {
            update() {
                axios.get('/vue/getBest?vue=q9F~<J').then(
                    (response) => {
                        this.users = response.data;
                    }
                )
            },
        }
    }

</script>

<style scoped>
    .bold {
        font-weight: 500;
        font-style: normal;
        font-size: 15.0px;
        color: rgb(30, 30, 30);
        text-align: center;
    }
    .vacant{
        font-weight: 500;
        font-style: normal;
        font-size: 12.0px;
        color: rgba(123, 123, 123, 1.0);
        text-align: center;
        line-height: 21.0px;
    }
    p{
        margin-bottom: 0 !important;
    }
    .rounded-circle{
        border: none !important;
        padding: 1px;
        width: 128px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    }
</style>
