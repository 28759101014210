<template>
    <div class="video_modal">
        <video-player class="video-player-box"
                      ref="videoPlayerRef"
                      :options="playerOptions"
                      @ready="onPlayerReady" />
    </div>
</template>

<script>
    //import VueVideoPlayer from 'vue-video-player';
    //Vue.use(VueVideoPlayer);
    import {videoPlayer} from 'vue-video-player';

    // @note Required for support m3u8. Else: VIDEOJS: ERROR: (CODE:4 MEDIA_ERR_SRC_NOT_SUPPORTED) No compatible source was found for this media.
    import 'video.js'; // OR: import videojs from 'video.js';
    import 'videojs-contrib-hls';
    import 'video.js/dist/video-js.css';

    export default {
        components: { videoPlayer },
        data: function() {
            return {
                playerOptions: {
                    fluid: true,
                    sources: [{
                        type: 'application/x-mpegurl',
                        src: this.url,
                        // withCredentials: false,
                    }],
                    // controlBar: {
                    //     timeDivider: false,
                    //     durationDisplay: false,
                    // },
                    // flash: {hls: {withCredentials: false}},
                    // html5: {hls: {withCredentials: false}},
                    name: 'player',
                },
            };
        },
        props: ['url']
        ,
        computed: {
            player() {
                return this.$refs.videoPlayerRef.player;
            }
        },
        mounted() {
            window.vuePlayerModal = this;
        },
        methods: {
            onPlayerReady(player) {
                /*const hls = player.tech({IWillNotUseThisInPlugins: true}).hls;

                player.tech_.hls.xhr.beforeRequest = function(options) {
                    /!* options: {
                        timeout: 45000,
                        uri: "https://d1uava4t3l5aij.cloudfront.net/recordings/1582812531578/video_2020-02-27360.m3u8",
                        withCredentials: false,
                        callback: ƒ(e,t),
                    } *!/

                    return options;
                };*/
            },
        }
    };
</script>

<style>
.video_modal{
    width: 100%;
}
</style>
