<template>
    <div>
        <v-app>
            <div class="d-none d-md-flex justify-content-center">
                <v-timeline>
                    <v-timeline-item
                        v-for="(lesson, index) in lessons"
                        :key="index"
                        color="#5e498c"
                        large
                    >
                        <template v-slot:opposite>
                    <span
                        :class="`headline font-weight-bold orig--text`"
                        v-text="lesson.date"
                    ></span>
                        </template>
                        <v-card class="elevation-2">
                            <v-card-title class="headline corp">{{lesson.title}}</v-card-title>
                            <v-card-text v-html="lesson.description">

                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </div>

            <div class="d-xs-flex d-sm-flex d-md-none">
                <v-timeline dense>
                    <v-timeline-item
                        v-for="(lesson, index) in lessons"
                        :key="index"
                        color="#5e498c"
                        large
                    >
                    <span
                        :class="`headline font-weight-bold orig--text ml-2 mb-2`"
                        v-text="lesson.date"
                    ></span>
                        <v-card class="elevation-2">
                            <v-card-title class="headline corp">{{lesson.title}}</v-card-title>
                            <v-card-text v-html="lesson.description">
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </div>
        </v-app>
    </div>

</template>

<script>

    export default {
        data: function () {
            return {
                lessons: [],
            }
        },
        name: "Lessons",
        mounted() {
            this.update()
        },
        methods: {
            update() {
                axios.get('/vue/getLessons?vue=q9F~<J').then(
                    (response) => {
                        this.lessons = response.data;
                    }
                )
            },
        }
    }

</script>

<style scoped>
    .corp {
        color: #5e498c;
        font-size: 1.2rem !important;;
        font-weight: 500;
    }

    .card {
        min-height: 470px;
    }

    .card-header {
        min-height: 75px;
    }

    .orig--text {
        color: #5e498c;
    }

    .v-card__title {
        min-width: 500px;
    }

    @media (max-width: 700px) {
        .v-card__title {
            min-width: unset;
        }
    }

    .v-application--wrap{
        min-height: unset !important;
    }
</style>
