<template>
    <div>
        <div class="row ml-10 mr-10 justify-content-between">
            <h1>Страницы</h1>
            <button title="Добавить" @click="addpage" type="submit" class="btn btn-primary ml-3"><i
                class="fa fa-plus"></i></button>
        </div>
        <div class="container login-card login-card-8">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Название</th>
                    <th scope="col">slug</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(page,index) in pages" :key="index">
                    <th scope="row">{{page.id}}</th>
                    <td>{{page.title}}</td>
                    <td>{{page.slug}}</td>
                    <td>
                        <i @click="editpage(page)" class="fa fa-pencil btn btn-success"></i>
                        <i @click="deletepage(page.id)" class="fa fa-times btn btn-danger"></i>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li @click.prevent="getpages(pagination.prev_page_url)"
                        :class="{disabled: !pagination.prev_page_url}"
                        class="page-item">
                        <a class="page-link" href="#">Назад</a>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                            {{pagination.last_page}}</a>
                    </li>
                    <li class="page-item"
                        @click.prevent="getpages(pagination.next_page_url)"
                        :class="{disabled: !pagination.next_page_url}">
                        <a
                            class="page-link" href="#">Следующая</a>
                    </li>
                </ul>
            </nav>


        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import 'summernote';
    export default {
        name: "pages",
        data() {
            return {
                pages: [],
                page: {
                    id: '',
                    title: '',
                    date: '',
                    description: ''
                },
                pagination: {},
            }
        },
        methods: {
            getpages(page_url) {
                if (!page_url) {
                    page_url = '/api/pages?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                } else {
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get(page_url)
                    .then(res => {
                        this.pages = res.data.data
                        this.makePagination(res.data)
                    })
            },
            deletepage(id) {
                axios
                    .delete(`/api/pages/${id}`, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                .then(()=>{
                    this.getpages()
                })
            },
            addpage() {
                window.location = `/admin/page/new`
            },
            editpage(page) {
                window.location = `/admin/page/${page.id}`
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
        },
        mounted() {
            this.getpages()
        }
    }
</script>

<style scoped>
    .form-row {
        background: #fff;
        padding: 10px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }
</style>
