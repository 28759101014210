<template>
    <div class="search">
        <input @keydown.enter="goToSearch" v-model="query" type="text" class="form-control mr-2" placeholder="Поиск по статьям">
        <i @click="goToSearch" class="fa fa-search" aria-hidden="true"></i>
    </div>
</template>

<script>
    export default {
        name: "search",
        data(){
            return{
                query: ''
            }
        },
        methods:{
            goToSearch(){
                window.location = `/profile/articles?q=${this.query}`
            }
        }
    }
</script>

<style scoped>
    .search{
        display: flex;
        align-items: center;
        color: white;
        font-size: 22px;
    }
    .fa:hover{
        color: #a7caff;
        cursor: pointer;
    }
</style>
