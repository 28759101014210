<template>
    <div>

        <div class="input-group mb-4">
            <div class="input-group-prepend mb-2">
                <div class="input-group-text" id="inputGroupPrepend"><i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <input v-model="query" @input="queryArticle()" id="input" type="text" class="form-control mb-2"
                   aria-describedby="inputGroupPrepend" placeholder="Поиск статьи">
        </div>
        <div v-if="query != ''" class="list-group">
            <h3>Результаты поиска</h3>
            <p v-if="searchres.length === 0">Не найдено статей по запросу <i><b>{{query}}</b></i></p>
            <a v-if="searchres.length > 0" v-for="(article, index) in searchres" :href="article.url"
               class=" mb-2 list-group-item list-group-item-action flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{article.title}}</h5>
                    <small>{{ article.hits }} просмотров</small>
                </div>
            </a>

        </div>
        <div class="login-card" v-if="query === ''">
            <div class="card-header ml-2 mt-2">
                Категории статей
            </div>
            <div class="card-body">
                <div class="list-group mt-3">
                    <router-link tag="a" v-for="(category, index) in categories" v-if="category.count > 0" :key="index"
                                 :to="{ name: 'articles', params: { category: category.slug}}"
                                 class=" mb-2 list-group-item list-group-item-action flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{category.title}}</h5>
                            <small>{{ category.count }} статей</small>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Articles",
        data: function () {
            return {
                categories: [],
                searchres: [],
                query: ''
            }
        },
        methods: {
            getCategories() {
                axios.get(`/vue/getCategories`).then(
                    (response) => {
                        this.categories = response.data;
                    }
                )
            },
            queryArticle() {
                if (this.query != '') {
                    axios.get(`/vue/queryArticle/${this.query}`).then(
                        (response) => {
                            this.searchres = response.data;
                        }
                    )
                }
            }
        },
        mounted() {
            this.getCategories()
            if(this.$route.query.q){
                this.query = this.$route.query.q
            }
            this.queryArticle()
        },
    }
</script>

<style scoped>
    a:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    .card-header{
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        border-bottom: 0px;
    }
    .list-group-item{
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        border-radius: 8px !important;
    }
    .input-group-text {
        background-color: white;
    }
    .form-control {
        height: calc(1.6em + .75rem + 5px);
        box-shadow: 0px 4px 16px rgba(51, 51, 51, 0.08), 0px 4px 4px rgba(51, 51, 51, 0.04);
        font-weight: 400;
        font-style: normal;
        font-size: 15.0px;
        color: rgba(17, 17, 17, 0.48);
        text-align: left;
        line-height: 24.0px;
    }
</style>
