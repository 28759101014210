<template>
    <div>
        <div v-if="getPermission('user_management')">
            <div class="row ml-10 mr-10 justify-content-between">
                <h1>Роли</h1>
                <button title="Добавить" @click="dialog = true; noedit();" type="submit" class="btn btn-primary ml-3"><i
                    class="fa fa-plus"></i></button>
            </div>
            <div class="container login-card login-card-8">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Роль</th>
                        <th scope="col">Описание</th>
                        <th scope="col">Разрешения</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(role,index) in roles" :key="index">
                        <th scope="row">{{role.id}}</th>
                        <td>{{role.name}}</td>
                        <td>{{role.description}}</td>
                        <td><p class="zero" v-for="(per,i) in rp[role.name]" :key="i">{{per}}</p></td>
                        <td>
                            <i @click="dialog = true; editrole(role);" class="fa fa-pencil btn btn-success"></i>
                            <i @click="deleterole(role.id)" class="fa fa-times btn btn-danger"></i>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <v-dialog
                    light
                    v-model="dialog"
                    max-width="800px"
                >
                    <form @submit.prevent="addrole">
                        <div class="col-8" v-if="!edit">
                            <input v-model="role.name" name="name" id="name" type="text"
                                   class="form-control"
                                   placeholder="Имя Роли">
                            <small>Имя роли</small>
                        </div>
                        <div class="col-8" v-else>
                            <input v-model="role.name" name="name" id="name" type="text"
                                   class="form-control"
                                   placeholder="Имя Роли" disabled>
                            <small class="form-text text-muted">Имя роли</small>
                        </div>
                        <div class="col-8">
                            <input v-model="role.description" name="description" id="description" type="text"
                                   class="form-control"
                                   placeholder="Название должности">
                            <small class="form-text text-muted">Описание доступа</small>
                        </div>
                        <p class="ml-3">Разрешения</p>
                        <div v-for="(roles,i) in rp.all" :key="i" class="form-check ml-3">
                            <input v-model="role.permissions" class="form-check-input" :id="roles" type="checkbox"
                                   :value="roles">
                            <label :for="roles" class="form-check-label">{{roles}}</label>
                        </div>
                        <div class="col-4">
                            <button v-if="edit" type="submit" class="btn btn-success">Изменить</button>
                            <button v-else type="submit" class="btn btn-success">Добавить</button>
                        </div>
                    </form>
                </v-dialog>
            </div>
        </div>
        <div v-else>
            <h3 class="text-center">У вас нет доступа на данную страницу</h3>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: "roles",
        data() {
            return {
                roles: [],
                rp: [],
                role: {
                    id: '',
                    name: '',
                    description: '',
                    permissions: []
                },
                dialog: false,
                edit: false,
                pagination: {},
            }
        },
        computed: {
            ...mapGetters(['allPermissions', 'getPermission']),
        }
        ,
        methods: {
            ...mapActions(['getpermissions']),
            getroles() {
                axios.get('/api/roles?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                    .then(res => {
                        console.log(res)
                        this.roles = res.data
                    })
                axios.get('/api/role/permissions?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                    .then(res => {
                        console.log(res)
                        this.rp = res.data
                    })
            },
            deleterole(id) {
                axios
                    .delete(`/api/roles/${id}`, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                .then(()=>{
                    this.getroles()
                })
            },
            addrole() {
                if (this.edit === false) {
                    axios
                        .post(`/api/roles`, {
                            name: this.role.name,
                            description: this.role.description,
                            permissions: this.role.permissions

                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.role.name = ''
                            this.role.description = ''
                            this.role.permissions = []
                            this.dialog = false
                            this.getroles()
                        })
                } else {
                    axios
                        .put(`/api/roles/${this.role.id}`, {
                            name: this.role.name,
                            description: this.role.description,
                            permissions: this.role.permissions
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.role.id = ''
                            this.role.name = ''
                            this.role.permissions = []
                            this.edit = false
                            this.dialog = false
                            this.getroles()
                        })
                }

            },
            editrole(role) {
                this.edit = true
                this.role.id = role.id;
                this.role.name = role.name;
                this.role.description = role.description;
                this.role.permissions = this.rp[role.name]
            },
            noedit() {
                this.edit = false
                this.role.id = ''
                this.role.name = ''
                this.role.description = ''
                this.role.permissions = []
            },
        },
        mounted() {
            this.getroles()
            this.getpermissions()
        }
    }
</script>

<style scoped>
    form {
        background: #fff;
    }

    .form-row {
        background: #fff;
        padding: 10px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }

    .zero {
        margin: 0 !important;
    }
</style>
