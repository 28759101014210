<template>
    <div>
        <carousel :per-page="1" :perPageCustom="[[0,1],[580, 2],[992, 3]]" :navigation-enabled="true">
            <slide v-for="(newz, index) in news" :key="index">
                <div class="login-card">
                    <img @click="goToNews('/news/'+ newz.id)" v-if="newz.image" class="card-img-top" :src="newz.image" alt="">
                    <img @click="goToNews('/news/'+ newz.id)" v-else class="card-img-top" src="/uploads/news/news.png" alt="">
                    <div class="card-body pb-0">
                        <div class="bb" @click="goToNews('/news/'+ newz.id)">
                            <h5 class="card-title title">
                                {{newz.title}}
                            </h5>
                            <p class="card-text preview">
                                {{newz.preview}}
                            </p>
                        </div>
                        <hr>
                        <div class="d-flex justify-content-between">
                            <p><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{newz.date}}</p>
                            <div class="mr-4">
                                <like-comment :id="newz.id.toString()" :noclick="true"></like-comment>
                            </div>
                        </div>
                    </div>
                </div>
            </slide>
        </carousel>
    </div>

</template>

<script>

    export default {
        data: function () {
            return {
                news: [],
            }
        },
        name: "MainNews",
        mounted() {
            this.update()
        },
        methods: {
            update() {
                axios.get(`/news/last?vue=q9F~<J`).then(
                    (response) => {
                        this.news = response.data;
                    }
                )
            },
            goToNews(url){
                window.location = url;
            }

        }
    }

</script>

<style scoped>
    .read-more a{
        color: rgba(0, 0, 0, 1.0);
        font-weight: 500;
        font-style: normal;
        font-size: 12.0px;
    }
    .bb{
        min-height: 112px;
    }
    .title{
        font-size: 14px !important;
        font-weight: 500;
    }
    .preview{
        font-family: "Roboto", Helvetica, Arial, serif;
        font-weight: 500;
        font-style: normal;
        font-size: 12.0px;
        color: rgba(115, 115, 115, 1.0);
    }

    .login-card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 16px;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.04), 3px 0px 6px rgba(0, 0, 0, 0.04), -3px 0px 6px rgba(33, 36, 41, 0.04);
         left: 2%;
        max-width: 90%;
        min-height: 470px;
    }
    .up {
        min-height: 130px;
    }
    .card-img-top{
        max-height: 250px !important;
    }
</style>
