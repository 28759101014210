<template>
    <div>
        <div class="row ml-10 mr-10 justify-content-between">
            <h1>Локации</h1>
            <button title="Добавить" @click="dialog = true; noedit();" type="submit" class="btn btn-primary ml-3"><i
                class="fa fa-plus"></i></button>
        </div>
        <div class="container login-card login-card-8">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Название</th>
                    <th scope="col">Адресс</th>
                    <th scope="col">Наша</th>
                    <th scope="col">Активна</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(location,index) in locations" :key="index">
                    <th scope="row">{{location.id}}</th>
                    <td>{{location.title}}</td>
                    <td>{{location.city}} {{location.address}}</td>
                    <td v-if="location.bc">Да</td>
                    <td v-else>Нет</td>
                    <td v-if="location.active">Да</td>
                    <td v-else>Нет</td>
                    <td>
                        <i @click="dialog = true; editLocation(location);" class="fa fa-pencil btn btn-success"></i>
                        <i @click="deleteLocation(location.id)" class="fa fa-times btn btn-danger"></i>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li @click.prevent="getLocations(pagination.prev_page_url)"
                        :class="{disabled: !pagination.prev_page_url}"
                        class="page-item">
                        <a class="page-link" href="#">Назад</a>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                            {{pagination.last_page}}</a>
                    </li>
                    <li class="page-item"
                        @click.prevent="getLocations(pagination.next_page_url)"
                        :class="{disabled: !pagination.next_page_url}">
                        <a
                            class="page-link" href="#">Следующая</a>
                    </li>
                </ul>
            </nav>

            <v-dialog
                light
                v-model="dialog"
                max-width="800px"
            >
                <form @submit.prevent="addLocation">
                    <div class="form-row">
                        <div class="col-6">
                            <input v-model="location.title" name="title" id="title" type="text" class="form-control"
                                   placeholder="Название локации">
                            <small class="form-text text-muted">Название локации</small>
                        </div>
                        <div class="col-6">
                            <input v-model="location.city" name="city" id="city" type="text" class="form-control"
                                   placeholder="Город">
                            <small class="form-text text-muted">Город</small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-8">
                            <input v-model="location.address" name="address" id="address" type="text"
                                   class="form-control"
                                   placeholder="Адресс локации">
                            <small class="form-text text-muted">Адрес локации</small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-2">
                            <div class="form-check"><input v-model="location.bc" class="form-check-input" name="bc"
                                                           type="checkbox" id="bc">
                                <label class="form-check-label" for="bc">
                                    Наша клиника
                                </label></div>
                        </div>
                        <div class="col-2">
                            <div class="form-check"><input v-model="location.active" class="form-check-input"
                                                           name="active" type="checkbox"
                                                           id="active">
                                <label class="form-check-label" for="bc">
                                    Активна
                                </label></div>
                        </div>
                        <div class="col">
                            <button v-if="edit" type="submit" class="btn btn-success">Изменить</button>
                            <button v-else type="submit" class="btn btn-success">Добавить</button>
                        </div>
                    </div>
                </form>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Locations",
        data() {
            return {
                locations: [],
                location: {
                    id: '',
                    title: '',
                    city: '',
                    address: '',
                    bc: '',
                    active: ''

                },
                dialog: false,
                edit: false,
                pagination: {},
            }
        },
        methods: {
            getLocations(page_url) {
                if (!page_url){
                    page_url = '/api/locations?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }else{
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get(page_url)
                    .then(res => {
                        this.locations = res.data.data
                        this.makePagination(res.data)
                    })
            },
            deleteLocation(id) {
                axios
                    .delete(`/api/locations/${id}`, {headers: {'Authorization' : 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP' }})
                .then(()=>{
                    this.getLocations()
                })
            },
            addLocation() {
                if (this.edit === false) {
                    axios
                        .post(`/api/locations`, {
                            title: this.location.title,
                            city: this.location.city,
                            address: this.location.address,
                            bc: this.location.bc,
                            active: this.location.active

                        }, {headers: {'Authorization' : 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP' }})
                        .then(res => {
                            this.location.id = '';
                            this.location.title = '';
                            this.location.city = '';
                            this.location.address = '';
                            this.location.bc = '';
                            this.location.active = '';
                            this.dialog = false
                            this.getLocations()
                        })
                } else {
                    axios
                        .put(`/api/locations/${this.location.id}`, {
                            title: this.location.title,
                            city: this.location.city,
                            address: this.location.address,
                            bc: this.location.bc,
                            active: this.location.active
                        }, {headers: {'Authorization' : 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP' }})
                        .then(res => {
                            this.location.id = '';
                            this.location.title = '';
                            this.location.city = '';
                            this.location.address = '';
                            this.location.bc = '';
                            this.location.active = '';
                            this.edit = false
                            this.dialog = false
                            this.getLocations()
                        })
                }

            },
            editLocation(location) {
                this.edit = true
                this.location.id = location.id;
                this.location.title = location.title;
                this.location.address = location.address;
                this.location.city = location.city;
                this.location.bc = location.bc;
                this.location.active = location.active;
            },
            noedit() {
                this.edit = false
                this.location.id = '';
                this.location.title = '';
                this.location.city = '';
                this.location.address = '';
                this.location.bc = true;
                this.location.active = true;
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
        },
        mounted() {
            this.getLocations()
        }
    }
</script>

<style scoped>
    .form-row {
        background: #fff;
        padding: 10px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }
</style>
