<template>
    <div class="text-center ml-2">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <div class="nav-link dropdown-toggle wh"
                     v-bind="attrs"
                     v-on="on"
                >
                    {{profile.name}}<span class="caret"></span>
                </div>
            </template>
            <v-list>
                <v-list-item
                    @click="myprofile"
                >
                    <v-list-item-title>Мой профиль</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="profile.isAdmin"
                             @click="admin"
                >
                    <v-list-item-title>Админка</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="logout"
                >
                    <v-list-item-title>Выйти</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <span class="text-white online">Сейчас на сайте : {{profile.online}}</span>
    </div>
</template>

<script>
    export default {
        name: "dropdown",
        data: function () {
            return {
                profile: [],
            }
        },
        methods: {
            getInfo() {
                axios.get(`/vue/getInfo`)
                    .then((res) => {
                        this.profile = res.data;
                    })
            },
            myprofile() {
                window.location = this.profile.profile;
            },
            admin() {
                window.location = this.profile.admin;
            },
            logout() {
                document.getElementById('logout-form').submit()
            }
        },
        mounted() {
            this.getInfo();
        }
    }
</script>

<style scoped>
    .dropdown-toggle {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    .online {
        font-size: 11px;
    }
</style>
