export default {
    state: {
        videos: []
    },
    mutations: {
        updatevideos(state, videos){
            state.videos = videos
        }
    },
    actions: {
        getvideos({commit}) {
            axios.get('/api/admin/videos?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                .then(res => {
                    let videos = res.data
                    commit('updatevideos',videos)
                })

        },
    },
    getters: {
        allvideos(state){
            return state.videos
        },
        getvideo: (state) => (video) =>{
            for (let item of state.videos){
                if(item.filename === video){
                    return item.title;
                }
            }
        },

    }
}
