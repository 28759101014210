<template>
<div>
    <a :href="user.profileUrl">
        <img :src="user.avatarUrl"
             class="rounded-circle">
    </a>
</div>
</template>

<script>
    import {eventEmitter} from "../../app";
    export default {
        data() {
            return {
                user: []
            }
        },
        props: ['id'],
        methods: {
            getAvatar() {
                axios.get(`/vue/getUser/${this.id}`).then(
                    (response) => {
                        this.user = response.data;
                    }
                )
            },
        },
        mounted() {
            this.getAvatar()
            eventEmitter.$on('avatarUploaded', count => {
                this.getAvatar()
            })
        },

    }
</script>

<style scoped>
img{
    width: 45px;
    height: 45px;
}
</style>
