<template>
    <div>
        <h1 class="ml-2">Лучшие сотрудники</h1>

        <best-users size="50" show="0">
        </best-users>

        <div class="container login-card login-card-8">
            <div v-if="errored" class="alert alert-danger">
                Пользователи должны быть уникальными!
            </div>
            <form @submit.prevent="updateBest">
                <div v-for="(bes, index) in best" :key="bes.id">
                    <div class="form-group row">
                        <label class="col-3 col-form-label col-form-label" :for="bes.user_id">Сотрудник {{ index +
                            1}}</label>
                        <div class="col-5">
                            <select v-model="bes.user_id" class="form-control" :name="bes.user_id">
                                <option :value="null">Нет</option>
                                <option v-for="user in ACTIVE_USERS" :key="user.id" :value="user.id">{{user.surname}}
                                    {{user.name}}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
                <button type="submit" class="btn btn-primary">Изменить</button>
            </form>
        </div>
    </div>
</template>

<script>
    import {eventEmitter} from '../../../app';
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: "Best",
        data() {
            return {
                best: '',
                errored: false
            }
        },
        computed: {
            ...mapGetters(['ACTIVE_USERS'])
        },
        methods: {
            ...mapActions(['getusers']),
            getBest() {
                axios.get('/api/bests?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                    .then(res => this.best = res.data)
            },
            updateBest(){
                axios
                    .post(`/api/bests/`, {
                        1: this.best[0].user_id,
                        2: this.best[1].user_id,
                        3: this.best[2].user_id,
                        4: this.best[3].user_id,
                        5: this.best[4].user_id,
                        6: this.best[5].user_id,
                        7: this.best[6].user_id,
                        8: this.best[7].user_id,

                    }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                    .then(res => {
                        this.getBest()
                        eventEmitter.$emit('bestchange')
                        this.errored = false;
                    }).catch(e => {
                        this.errored = true;
                })
            }
        },
        mounted() {
            this.getusers()
            this.getBest()
        }
    }
</script>

<style scoped>

</style>
