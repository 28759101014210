<template>
    <div class="container">
        <h2 class="text-center mb-2 mt-4">Новости Компании</h2>

        <div class="row">
            <div class="col-4 text-center mr-auto ml-auto mb-4 newsgray">
                <hr>
            </div>
        </div>

        <div class="row mb-5">
        </div>

        <div class="card-group justify-content-around align-content-center">
            <div v-for="newz in news" :key="newz.id" class="login-card mb-9">
                <img @click="goToNews('/news/'+ newz.id)" v-if="newz.image" class="card-img-top" :src="'/uploads/news/' + newz.id + '/' + newz.image" alt="">
                <img @click="goToNews('/news/'+ newz.id)" v-else class="card-img-top" src="/uploads/news/news.png" alt="">
                <div class="card-body pb-0">
                    <div class="bb" @click="goToNews('/news/'+ newz.id)">
                    <h5 class="card-title title">
                        {{newz.title}}
                    </h5>
                    <p class="card-text preview">
                        {{newz.preview}}
                    </p>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between">
                        <p><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{dateshow(newz.created_at)}}</p>
                        <div class="mr-4">
                            <like-comment :id="newz.id.toString()" :noclick="true"></like-comment>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-3">
            <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li @click.prevent="getnews(pagination.prev_page_url)"
                        :class="{disabled: !pagination.prev_page_url}"
                        class="page-item">
                        <a class="page-link" href="#">Назад</a>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                            {{pagination.last_page}}</a>
                    </li>
                    <li class="page-item"
                        @click.prevent="getnews(pagination.next_page_url)"
                        :class="{disabled: !pagination.next_page_url}">
                        <a
                            class="page-link" href="#">Следующая</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AllNews",
        data() {
            return {
                news: [],
                pagination: {},
            }
        },
        methods: {
            getnews(page_url) {
                if (!page_url) {
                    page_url = '/api/news?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP&pages=12'
                } else {
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP&pages=12'
                }
                axios.get(page_url)
                    .then(res => {
                        this.news = res.data.data
                        this.makePagination(res.data)
                    })
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
            dateshow(date) {
                let d = new Date(Date.parse(date))
                return new Date(Date.parse(date)).toLocaleDateString()
            },
            goToNews(url){
                window.location = url;
            }
        },
        mounted() {
            this.getnews()
        }
    }
</script>

<style scoped>
    img {
        max-height: 221px;
        border-radius: 8px;
    }
    .bb{
        min-height: 112px;
    }

    .login-card{
        max-width: 330px;
    }
    @media (max-width: 566px) {
        .login-card{
            max-width: 400px;
            margin: auto;
        }
    }
    .title{
        font-size: 14px !important;
        font-weight: 500;
        line-height: 1.4rem;
    }
    .preview{
        font-family: "Roboto", Helvetica, Arial, serif;
        font-weight: 500;
        font-style: normal;
        font-size: 12.0px;
        color: rgba(115, 115, 115, 1.0);
    }
</style>
