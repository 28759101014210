<template>
    <div>
        <div v-if="getPermission('user_management')">
            <div class="row ml-10 mr-10 justify-content-between">
                <h1>Разрешения</h1>
                <button title="Добавить" @click="dialog = true; noedit();" type="submit" class="btn btn-primary ml-3"><i
                    class="fa fa-plus"></i></button>
            </div>
            <div class="container login-card login-card-8">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Разрешение</th>
                        <th scope="col">Описание</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(permission,index) in permissions" :key="index">
                        <th scope="row">{{permission.id}}</th>
                        <td>{{permission.name}}</td>
                        <td>{{permission.description}}</td>
                        <td>
                            <i @click="dialog = true; editpermission(permission);"
                               class="fa fa-pencil btn btn-success"></i>
                            <i @click="deletepermission(permission.id)" class="fa fa-times btn btn-danger"></i>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <v-dialog
                    light
                    v-model="dialog"
                    max-width="800px"
                >
                    <form @submit.prevent="addpermission">
                        <div class="form-row">
                            <div class="col" v-if="!edit">
                                <input v-model="permission.name" name="name" id="name" type="text"
                                       class="form-control"
                                       placeholder="Имя разрешения">
                                <small class="form-text text-muted">Имя разрешения</small>
                            </div>
                            <div class="col" v-else>
                                <input v-model="permission.name" name="name" id="name" type="text"
                                       class="form-control"
                                       placeholder="Имя разрешения" disabled>
                                <small class="form-text text-muted">Имя разрешения</small>
                            </div>
                            <div class="col">
                                <input v-model="permission.description" name="description" id="description" type="text"
                                       class="form-control"
                                       placeholder="Описание разрешения">
                                <small class="form-text text-muted">Описание разрешения</small>
                            </div>
                            <div class="col">
                                <button v-if="edit" type="submit" class="btn btn-success">Изменить</button>
                                <button v-else type="submit" class="btn btn-success">Добавить</button>
                            </div>
                        </div>

                    </form>
                </v-dialog>
            </div>
        </div>
        <div v-else>
            <h3 class="text-center">У вас нет доступа на данную страницу</h3>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "permissions",
        data() {
            return {
                permissions: [],
                permission: {
                    id: '',
                    name: '',
                    description: '',
                },
                dialog: false,
                edit: false,
            }
        },
        computed: {
            ...mapGetters(['allPermissions', 'getPermission']),
        },
        methods: {
            ...mapActions(['getpermissions']),
            getpermissionss() {
                axios.get('/api/permissions?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                    .then(res => {
                        console.log(res)
                        this.permissions = res.data
                    })
            },
            deletepermission(id) {
                axios
                    .delete(`/api/permissions/${id}`, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                .then(()=>{
                    this.getpermissionss()
                })
            },
            addpermission() {
                if (this.edit === false) {
                    axios
                        .post(`/api/permissions`, {
                            name: this.permission.name,
                            description: this.permission.description,

                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.permission.name = ''
                            this.permission.description = ''
                            this.dialog = false
                            this.getpermissionss()
                        })
                } else {
                    axios
                        .put(`/api/permissions/${this.permission.id}`, {
                            name: this.permission.name,
                            description: this.permission.description,
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.permission.id = ''
                            this.permission.name = ''
                            this.edit = false
                            this.dialog = false
                            this.getpermissionss()
                        })
                }

            },
            editpermission(permission) {
                this.edit = true
                this.permission.id = permission.id;
                this.permission.name = permission.name;
                this.permission.description = permission.description;
            },
            noedit() {
                this.edit = false
                this.permission.id = ''
                this.permission.name = ''
                this.permission.description = ''
            },
        },
        mounted() {
            this.getpermissionss()
            this.getpermissions()
        }
    }
</script>

<style scoped>
    form {
        background: #fff;
    }

    .form-row {
        background: #fff;
        padding: 10px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }

    .zero {
        margin: 0 !important;
    }
</style>
