<template>
    <div>
        <div class="row ml-2">
            <p class="float-left" v-if="!count.liked">
                <a @click.prevent="addLike" href="#" class="card-link"><i
                    class="fa fa-heart-o"
                    aria-hidden="true"></i></a> {{ count.likes }}</p>
            <p class="float-left" v-if="count.liked">
                <i
                    v-popover="{name: id}"
                    class="fa fa-heart-o"
                    aria-hidden="true"></i> {{ count.likes }}</p>
            <popover :name="id.toString()">
                <a v-for="(user,index) in count.users" :key="index" :href="user.userurl" :title="user.username"><img
                    :src="user.avatar"
                    width="20" height="20" class="rounded-circle ml-2">
                </a>
            </popover>
        </div>
    </div>
</template>

<script>

    export default {
        data: function () {
            return {
                count: [],
            }
        },
        name: "LikeReply",
        props: {'id': String},
        mounted() {
            this.update()
        },
        methods: {
            update() {
                axios.get(`/vue/getReplyLikes/${this.id}`)
                    .then((response) => this.count = response.data)
            },
            addLike() {
                axios.get(this.count.url)
                    .then(() => {
                        this.update();
                    });

            }

        }
    }

</script>

<style scoped>
    [data-popover] {
        color: #f9f9f9;

        line-height: 1.5;
        margin: 5px;
    }
</style>
