export default {
    state: {
        locations: []
    },
    mutations: {
        updatelocations(state, locations){
            state.locations = locations
        }
    },
    actions: {
        getlocations({commit}) {
            axios.get('/api/locations?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP&all=1')
                .then(res => {
                    let locations = res.data
                    commit('updatelocations',locations)
                })

        },
    },
    getters: {
        alllocations(state){
            return state.locations
        },
        getLocation: (state) => (location) =>{
            for (let item of state.locations){
                if(item.id === location){
                    return item;
                }
            }
        },

    }
}
