<template>
    <div class="container">
        <div class="login-card mb-4 mt-4">
            <div v-if="news.image" class="top mb-4" :style="style">

            </div>
            <div v-else class="top"
                 style="background: url('/uploads/news/news.png') center no-repeat;background-size: cover;">
            </div>
            <div class="titleb comdate mb-4">
                {{news.title}}
            </div>
            <div class="d-flex justify-content-between comdate">
                <p><i class="fa fa-calendar-check-o" aria-hidden="true"></i> {{dateshow(news.created_at)}}</p>
                <div class="mr-4">
                    <like-comment :id="id" :noclick="true"></like-comment>
                </div>
            </div>
            <hr class="comdate">
            <div class="card-body comdate mb-4" v-html="news.body">
            </div>
            <div v-if="news.videofilename" class="comdate mb-2">
                <h4>{{getvideo(news.videofilename)}}</h4>
                <test :url="`/watch/${news.videofilename}`"></test>
            </div>
            <div v-if="news.videofilename1" class="comdate mb-2">
                <h4>{{getvideo(news.videofilename1)}}</h4>
                <test :url="`/watch/${news.videofilename1}`"></test>
            </div>
            <div v-if="news.videofilename2" class="comdate mb-2">
                <h4>{{getvideo(news.videofilename2)}}</h4>
                <test :url="`/watch/${news.videofilename2}`"></test>
            </div>


        </div>
        <comment-news :id="id"></comment-news>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    export default {
        name: "SingleNews",
        data: function () {
            return {
                id: this.$route.params['id'],
                news: [],
                style: ''
            }
        },
        computed:{
            ...mapGetters(['getvideo'])
        },
        methods: {
            ...mapActions(['getvideos']),
            getNews() {
                axios.get(`/vue/news/${this.id}`)
                    .then(res => {
                        this.news = res.data;
                        this.style = `background: url('/uploads/news/${this.news.id}/${this.news.image}') center no-repeat; background-size: cover;`
                    })
            },
            dateshow(date) {
                let d = new Date(Date.parse(date))
                return new Date(Date.parse(date)).toLocaleDateString()
            },
        },
        mounted() {
            this.getNews()
            this.getvideos()
        }
    }
</script>

<style scoped>
    .titleb {
        position: relative;

        color: #0a0e14;
        font-family: 'Roboto', Helvetica, Arial, serif;
        font-size: 24.0px;
        font-style: normal;
        text-align: left;
        line-height: 40px;
        font-weight: 500;

    }
    @media (max-width: 764px){
        .titleb{
            top: 50%;
        }
    }
    .top {
        height: 390px;
        margin-left: 45px;
        margin-right: 45px;
        margin-top: 45px;
        margin-bottom: 14px;
        border-radius: 12px;
        display: flex;
    }
    .comdate{
        margin-left: 45px;
        margin-right: 45px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .login-card{
        border-radius: 8px !important;
    }
</style>
