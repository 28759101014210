<template>
    <div>
        <div class="login-card">
            <div class="card-header ml-2 mt-2">
                {{title}}
            </div>
            <div class="card-body">
                <div class="list-group">
                    <a v-for="(article, index) in articles" :href="article.url"
                       class=" mb-2 list-group-item list-group-item-action flex-column align-items-start">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{article.title}}</h5>
                            <small>{{ article.hits }} просмотров</small>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Articles",
        data: function () {
            return {
                cat: this.$route.params['category'],
                articles: [],
                title: '',
            }
        },
        methods: {
            getCategories() {
                axios.get(`/vue/articles/${this.cat}`).then(
                    (response) => {
                        this.articles = response.data.sort(function (a,b) {
                            if(a.title < b.title) { return -1; }
                            if(a.title > b.title) { return 1; }
                            return 0
                        });
                    }
                )
            },
            getTitle() {
                axios.get(`/vue/getTitle/${this.cat}`).then(
                    (response) => {
                        this.title = response.data;
                    })
            },
        },
        mounted() {
            this.getTitle()
            this.getCategories()
        },
    }
</script>

<style scoped>
    a:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .card-header {
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        border-bottom: 0px;
    }

    .list-group-item {
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
        border-radius: 8px !important;
    }

    .card-body {
        padding-top: 0.25rem;
    }
</style>
