<template>
    <div>
        <div class="row ml-10 mr-10 justify-content-between">
            <h1>Категории</h1>
            <button title="Добавить" @click="dialog = true; noedit();" type="submit" class="btn btn-primary ml-3"><i
                class="fa fa-plus"></i></button>
        </div>
        <div class="container login-card login-card-8">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Название</th>
                    <th scope="col">slug</th>
                    <th scope="col">Разрешение</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(category,index) in categories" :key="index">
                    <th scope="row">{{category.id}}</th>
                    <td>{{category.title}}</td>
                    <td>{{category.slug}}</td>
                    <td>{{permisiions[category.permission_id]}}</td>
                    <td>
                        <i @click="dialog = true; editcategory(category);" class="fa fa-pencil btn btn-success"></i>
                        <i @click="deletecategory(category.id)" class="fa fa-times btn btn-danger"></i>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li @click.prevent="getcategories(pagination.prev_page_url)"
                        :class="{disabled: !pagination.prev_page_url}"
                        class="page-item">
                        <a class="page-link" href="#">Назад</a>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                            {{pagination.last_page}}</a>
                    </li>
                    <li class="page-item"
                        @click.prevent="getcategories(pagination.next_page_url)"
                        :class="{disabled: !pagination.next_page_url}">
                        <a
                            class="page-link" href="#">Следующая</a>
                    </li>
                </ul>
            </nav>

            <v-dialog
                light
                v-model="dialog"
                max-width="800px"
            >
                <form @submit.prevent="addcategory">
                    <div class="form-row">
                        <div class="col">
                            <input v-model="category.title" name="title" id="titlech" type="text" class="form-control"
                                   placeholder="Название категории">
                        </div>
                        <div class="col">
                            <select v-model="category.permission_id" name="permission_id" id="permission_id" class="form-control"
                            >
                                <option v-for="(name, id,index) in permisiions" :key="index" :value="id">{{ name}}</option>
                            </select>
                        </div>
                        <div class="col">
                            <button v-if="edit" type="submit" class="btn btn-success">Изменить</button>
                            <button v-else type="submit" class="btn btn-success">Добавить</button>
                        </div>
                    </div>
                </form>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "categories",
        data() {
            return {
                categories: [],
                category: {
                    id: '',
                    title: '',
                    permission_id: '',
                },
                permisiions: [],
                dialog: false,
                edit: false,
                pagination: {},
            }
        },
        methods: {
            getcategories(page_url) {
                if (!page_url) {
                    page_url = '/api/categories?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                } else {
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get('/api/permissions?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP')
                    .then(res => {
                        let result = {};
                        for (let item of res.data) {
                            result[item.id] = item.name;
                        }
                        this.permisiions = result;
                    })
                axios.get(page_url)
                    .then(res => {
                        this.categories = res.data.data
                        this.makePagination(res.data)
                    })


            },
            deletecategory(id) {
                axios
                    .delete(`/api/categories/${id}`, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                .then(() =>{
                    this.getcategories()
                })
            },
            addcategory() {
                if (this.edit === false) {
                    axios
                        .post(`/api/categories`, {
                            title: this.category.title,
                            permission_id: this.category.permission_id
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.category.title = ''
                            this.category.permission_id = ''
                            this.dialog = false
                            this.getcategories()
                        })
                } else {
                    axios
                        .put(`/api/categories/${this.category.id}`, {
                            title: this.category.title,
                            permission_id: this.category.permission_id
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.category.title = ''
                            this.category.permission_id = ''
                            this.edit = false
                            this.dialog = false
                            this.getcategories()
                        })
                }

            },
            editcategory(category) {
                this.edit = true
                this.category.id = category.id;
                this.category.title = category.title;
                this.category.permission_id = category.permission_id;
            },
            noedit() {
                this.edit = false
                this.category.id = ''
                this.category.title = ''
                this.category.permission_id = 1
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
        },
        mounted() {
            this.getcategories()
        }
    }
</script>

<style scoped>
    .form-row {
        background: #fff;
        padding: 10px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }
</style>
