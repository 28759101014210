<template>
    <div class="container-fluid">
        <div class="row justify-content-around">
            <div>
                <div class="d-flex justify-content-between end">
                <h3>Последние логи</h3>
                    <button @click="downloadLogins" class="btn btn-primary">Выгрузить</button>
                </div>
                <div class="login-card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">Пользователь</th>
                                <th scope="col">Действие</th>
                                <th scope="col">Дата</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(login,index) in logins" :key="index">
                                <td>{{users[login.user_id]}}</td>
                                <td v-if="login.state == 0">Вход</td>
                                <td v-if="login.state == 1">Выход</td>
                                <td>{{login.login_time}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                            <ul class="pagination">
                                <li @click.prevent="getLogins(pagination.prev_page_url)"
                                    :class="{disabled: !pagination.prev_page_url}"
                                    class="page-item">
                                    <a class="page-link" href="#">Назад</a>
                                </li>
                                <li class="page-item disabled">
                                    <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                                        {{pagination.last_page}}</a>
                                </li>
                                <li class="page-item"
                                    @click.prevent="getLogins(pagination.next_page_url)"
                                    :class="{disabled: !pagination.next_page_url}">
                                    <a
                                        class="page-link" href="#">Следующая</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div>
                <div class="d-flex justify-content-between end">
                <h3>Последние логи VPN</h3>
                <button @click="downloadVpn" class="btn btn-primary">Выгрузить</button>
                </div>
                <div class="login-card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">Пользователь VPN</th>
                                <th scope="col">IP VPN</th>
                                <th scope="col">Дата</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(login,index) in vpn" :key="index">
                                <td>{{login.name}}</td>
                                <td>{{login.ip}}</td>
                                <td>{{dateset(login.created_at)}}</td>
                            </tr>
                            </tbody>
                        </table>
                        <nav v-if="paginationv.last_page != 1" aria-label="Page navigation example">
                            <ul class="pagination">
                                <li @click.prevent="getVpn(paginationv.prev_page_url)"
                                    :class="{disabled: !paginationv.prev_page_url}"
                                    class="page-item">
                                    <a class="page-link" href="#">Назад</a>
                                </li>
                                <li class="page-item disabled">
                                    <a class="page-link" href="#">Страница {{ paginationv.current_page}} из
                                        {{paginationv.last_page}}</a>
                                </li>
                                <li class="page-item"
                                    @click.prevent="getVpn(paginationv.next_page_url)"
                                    :class="{disabled: !paginationv.next_page_url}">
                                    <a
                                        class="page-link" href="#">Следующая</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
            <div>
                <h3>Статистика</h3>
                <div class="login-card">
                    <div class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">Всего</th>
                                <th scope="col">Кол-во</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Статей</td>
                                <td>{{stats.articles}}</td>
                            </tr>
                            <tr>
                                <td>Новостей</td>
                                <td>{{stats.news}}</td>
                            </tr>
                            <tr>
                                <td>Пользователей</td>
                                <td>{{stats.users}}</td>
                            </tr>
                            <tr>
                                <td>Заявок</td>
                                <td>{{stats.issues}}</td>
                            </tr>
                            <tr>
                                <td>Пользователей онлайн</td>
                                <td>{{stats.online}}</td>
                            </tr>
                            <tr>
                                <td>Уроков</td>
                                <td>{{stats.lessons}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                logins: [],
                users: [],
                stats: [],
                vpn: [],
                pagination: {},
                paginationv: {}
            }
        },
        methods: {
            getLogins(page_url) {
                if (!page_url) {
                    page_url = '/api/admin/logins?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                } else {
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get(page_url)
                    .then(res => {
                        this.logins = res.data.logins.data
                        this.makePagination(res.data.logins)
                        this.users = res.data.users;
                    })


            },
            downloadVpn(){
                window.location = `/admin/export/vpn`
            },
            downloadLogins(){
                window.location = `/admin/export/logins`
            },
            getStats() {
                axios.get(`/api/admin/stats?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP`)
                    .then(res => {
                        this.stats = res.data;
                    })
            },
            getVpn(page_url) {
                if (!page_url) {
                    page_url = '/api/admin/vpn?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                } else {
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get(page_url)
                    .then(res => {
                        this.vpn = res.data.data
                        this.makePaginationv(res.data)
                    })
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
            makePaginationv(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.paginationv = pagination
            },
            dateset(date){
                let d = new Date(Date.parse(date))
                return new Date(Date.parse(date)).toLocaleDateString()  + ' ' + new Date(Date.parse(date)).toLocaleTimeString();
            },
        },
        mounted() {
            this.getLogins()
            this.getStats()
            this.getVpn()
        }

    }
</script>

<style scoped>
.end{
    align-items: end;
}
</style>
