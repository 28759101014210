<template>
    <div>
        <h2 class="text-center mb-2">Расписание занятий</h2>
        <div class="row">
            <div class="col-4 text-center mr-auto ml-auto newsgray">
                <hr>
            </div>
        </div>
        <div class="login-card login-card-8 card-body">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">Заголовок</th>
                <th scope="col">Дата</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="lesson in lessons" :key="lesson.id">
                <td>{{lesson.title}}</td>
                <td>{{lesson.date}}</td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LessonsAll",
        data: function () {
            return {
                lessons: [],
            }
        },
        methods: {
            getLessons() {
                axios.get(`/lesson/all`)
                    .then(res => {
                        this.lessons = res.data;
                    })
            },
            dateshow(date) {
                let d = new Date(Date.parse(date))
                return new Date(Date.parse(date)).toLocaleDateString()
            },
        },
        mounted(){
            this.getLessons()
        }
    }
</script>

<style scoped>

</style>
