<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <div class="login-card">
                    <div class="card-header">
                        Профиль
                    </div>
                    <div class="card-body text-center">
                        <div class="image">
                            <img
                                :src="user.avatarUrl"
                                width="118" height="118" class="rounded-circle mt-2 mb-6">
                        </div>
                        <div class="name">
                            {{user.name}}
                        </div>
                        <div v-if="user.auth">
                            <upload-form v-if="!user.hasAvatar" :id="this.id" text="Загрузить аватар"></upload-form>
                            <upload-form v-else :id="this.id" text="Обновить аватар"></upload-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="login-card">
                    <div class="card-header d-flex justify-space-between">
                        <div>
                            Личные данные
                        </div>
                        <div v-if="user.auth">
                            <i @click="dialog = true; validationErrors = ''; pass_success = false" class="fa fa-ellipsis-h" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="card-body">
                        <p>Должность: {{user.vacant}}</p>
                        <p>Мобильный телефон: {{user.phone}}</p>
                        <p>Email: {{user.email}}</p>
                        <p>День рождения: {{user.birth}}</p>
                        <div v-if="user.location">
                            <p>Локация: {{ user.location.title }}</p>
                            <p>Адрес: {{user.location.city}} {{ user.location.address }}</p>
                            <p v-if="!user.location.bc">Франшиза</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <last-articles :id="id"></last-articles>
            </div>
            <div class="col-lg-4">
                <birthdays></birthdays>
            </div>
        </div>

        <v-dialog
            light
            v-model="dialog"
            max-width="800px"
        >


            <form @submit.prevent="updateUser()">
                <validation-errors v-if="validationErrors"
                                   :errors="validationErrors">

                </validation-errors>
                <div v-if="pass_success" class="alert alert-success">
                    Ваш пароль успешно изменён
                </div>
                <div class="form-group row">
                    <div class="col">
                        <input id="name" type="text"
                               class="form-control" name="name"
                               v-model="myprofile.name" autocomplete="name" autofocus>
                        <small class="form-text text-muted">Имя</small>
                    </div>
                    <div class="col">
                        <input id="surname" type="text"
                               class="form-control" v-model="myprofile.surname" name="surname"
                               autocomplete="surname" autofocus>
                        <small class="form-text text-muted">Фамилия</small>
                    </div>
                </div>

                <div class="form-group row">

                    <div class="col">
                        <input id="phone" type="tel"
                               class="form-control" name="phone"
                               v-model="myprofile.phone" autocomplete="phone" autofocus>
                        <small class="form-text text-muted">Телефон</small>
                    </div>
                    <div class="col">
                        <input id="date_birth" type="date"
                               class="form-control"
                               name="date_birth"
                               v-model="myprofile.date_birth" autocomplete="name" autofocus>
                        <small class="form-text text-muted">Дата рожденья</small>
                    </div>
                </div>
                <div class="form-group row mb-0">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">Изменить</button>
                    </div>
                    <div class="col">
                        <button @click="dialog2 = true; validationErrors = false" class="btn btn-primary">Сменить пароль</button>
                    </div>

                </div>
            </form>
        </v-dialog>
        <v-dialog
            light
            v-model="dialog2"
            max-width="400px"
        >
            <form @submit.prevent="updatePassword()">

                <validation-errors v-if="validationErrors"
                                   :errors="validationErrors">

                </validation-errors>

                <div class="form-group row">
                    <div class="col">
                        <input id="password" type="password"
                               class="form-control"
                               name="password"
                               v-model="password" autocomplete="password" autofocus>
                        <small class="form-text text-muted mb-4">Новый пароль</small>
                        <input id="password_confirmation" type="password"
                               class="form-control"
                               name="password_confirmation"
                               v-model="password_conf" autocomplete="password" autofocus>
                        <small class="form-text text-muted mb-4">Подтверждение пароля</small>
                        <button type="submit" class="btn btn-primary">Изменить</button>
                        <button @click="cancel()" type="button" class="btn btn-primary">Отмена</button>
                    </div>
                </div>
            </form>
        </v-dialog>

    </div>
</template>

<script>
    export default {
        name: "Home",
        data: function () {
            return {
                id: this.$route.params['id'],
                user: [],
                myprofile: {
                    name: '',
                    surname: '',
                    phone: '',
                    date_birth: ''
                },
                password: '',
                password_conf: '',
                dialog: false,
                dialog2: false,
                validationErrors: '',
                pass_success: false
            }
        },
        methods: {
            getUser() {
                axios.get(`/vue/getUser/${this.id}`).then(
                    (response) => {
                        this.user = response.data;
                    }
                )
                axios.get(`/vue/getUser/${this.id}?my=true`)
                .then((res) =>{
                    this.myprofile.name = res.data.name;
                    this.myprofile.surname = res.data.surname;
                    this.myprofile.phone = res.data.phone;
                    this.myprofile.date_birth = res.data.date_birth;
                })
            },
            updateUser(){
                this.getUser()
                axios
                    .put(`/api/users/${this.id}`, {
                        name: this.myprofile.name,
                        surname: this.myprofile.surname,
                        phone: this.myprofile.phone,
                        date_birth: this.myprofile.date_birth,
                        editprofile: 'my'
                    }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                    .then(()=>{
                        this.getUser()
                        this.dialog = false
                    })
                    .catch(error => {
                        if (error.response.status === 422){
                            this.validationErrors = error.response.data.errors
                        }
                    })
            },
            updatePassword(){
                axios.put(`/api/users/${this.id}`, {
                       password: this.password,
                        password_confirmation: this.password_conf,
                        editprofile: 'password'
                    }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                    .then(()=>{
                        this.pass_success = true;
                        this.dialog2 = false
                        this.dialog = true;
                    })
                    .catch(error => {
                        if (error.response.status === 422){
                            this.validationErrors = error.response.data.errors
                        }
                })
                },
            cancel(){
                this.validationErrors = '';
                this.password = '';
                this.password_conf = '';
                this.dialog = true;
                this.dialog2 = false;
                this.pass_success = false;
            }
        },
        mounted() {
            this.getUser()
        },
        watch: {
            $route(to, from) {
                this.id = this.$route.params['id']
                this.getUser()
            }
        }
    }
</script>

<style scoped>
    .name {
        font-weight: 500;
        font-style: normal;
        font-size: 19.0px;
        color: rgba(51, 51, 51, 1.0);
        text-align: center;
        line-height: 23.5px;
    }

    .card-header {
        font-weight: 400;
        font-style: normal;
        font-size: 16.0px;
        color: rgba(33, 36, 41, 1.0);
        text-align: left;
        line-height: 20.0px;
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        border-bottom: 0px;
        margin-left: 8px;
        margin-top: 8px;
        margin-right: 8px;
    }

    .card-body text-center {
        margin-bottom: 8px;
    }

    .card-body {
        padding-left: 28px;
        padding-right: 28px;
    }

    .card-header d-flex justify-space-between {
        padding-bottom: 0.75rem;
        margin-top: 8px;
        margin-left: 8px;
        margin-right: 8px;
    }

    .login-card {
        min-height: 100%;
        border: 2px solid rgba(0, 0, 0, 0.125);
    }

    .fa {
        font-size: 22px;
    }
    .rounded-circle{
        border: none !important;
        padding: 1px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    form {
        background: #fff;
        padding: 16px;
        margin: 0;
    }
</style>
