require('./bootstrap');
window.Vue = require('vue');

import 'videojs-contrib-hls';
import Popover from 'vue-js-popover'
import VueCarousel from 'vue-carousel';
import VideoPlayer from 'vue-vjs-hls';
import VueRouter from 'vue-router';
import router from './router';
import vuetify from "./plugins/vuetify";
import store from "./store";

export const eventEmitter = new Vue()

Vue.use(Popover)
Vue.use(VueCarousel);
Vue.use(VideoPlayer);
Vue.use(VueRouter);



Vue.component('notification', require('./components/NavBar/notification.vue').default);
Vue.component('nav-avatar', require('./components/NavBar/avatar.vue').default);
Vue.component('dropdown', require('./components/NavBar/dropdown.vue').default);
Vue.component('test', require('./components/test').default)
Vue.component('search', require('./components/NavBar/search').default)

Vue.component('validation-errors', require('./components/ValidationErrors').default);
Vue.component('article-question', require('./components/ArticleQuestion').default);
Vue.component('lessons-all', require('./components/Pages/LessonsAll').default);

Vue.component('reply-issue', require('./components/replyIssue.vue').default);
Vue.component('comment-news', require('./components/CommentNews.vue').default);
Vue.component('like-comment', require('./components/LikeComment.vue').default);
Vue.component('birthdays', require('./components/Birthdays.vue').default);
Vue.component('last-articles', require('./components/LastArticles.vue').default);
// Profile pages and components
Vue.component('main-news', require('./components/MainPage/MainNews.vue').default);
Vue.component('best-users', require('./components/MainPage/BestUsers.vue').default);
Vue.component('lessons', require('./components/MainPage/Lessons.vue').default);
Vue.component('upload-form', require('./components/Pages/Profile/UploadForm.vue').default);
Vue.component('news-rating', require('./components/NewsRating.vue').default);
Vue.component('like-reply', require('./components/LikeReply').default);



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


const app = new Vue({
    el: '#app',
    router, vuetify, store
});
