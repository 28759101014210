<template>
    <div>
    <div v-if="getPermission('user_management')">
        <div class="row ml-10 mr-10 justify-content-between mb-3">
            <h1>Пользователи</h1>
            <button title="Добавить" @click="dialog = true; noedit();" type="submit" class="btn btn-primary ml-3"><i
                class="fa fa-plus"></i></button>
        </div>
        <div class="container-fluid login-card login-card-8">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">ФИО</th>
                    <th scope="col">Должность</th>
                    <th scope="col">Телефон</th>
                    <th scope="col">Email</th>
                    <th scope="col">Локация</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user,index) in users" v-if="user.id != 1" :key="index">
                    <th scope="row">{{user.id}}</th>
                    <td>{{user.surname}} {{user.name}}</td>
                    <td>{{vacants[user.vacant_id]}}</td>
                    <td>{{user.phone}}</td>
                    <td>{{user.email}}</td>
                    <td>{{locations[user.location_id]}}</td>
                    <td>
                        <i v-if="user.active == 1" @click="toggleUser(user.id)" class="fa fa-arrow-down btn btn-info"></i>
                        <i v-if="user.active == 0" @click="toggleUser(user.id)" class="fa fa-arrow-up btn btn-warning"></i>
                        <i @click="dialog = true; edituser(user);" class="fa fa-pencil btn btn-success"></i>
                        <i @click="deleteuser(user.id)" class="fa fa-times btn btn-danger"></i>
                    </td>
                </tr>
                </tbody>
            </table>

            <nav v-if="pagination.last_page != 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li @click.prevent="getusers(pagination.prev_page_url)"
                        :class="{disabled: !pagination.prev_page_url}"
                        class="page-item">
                        <a class="page-link" href="#">Назад</a>
                    </li>
                    <li class="page-item disabled">
                        <a class="page-link" href="#">Страница {{ pagination.current_page}} из
                            {{pagination.last_page}}</a>
                    </li>
                    <li class="page-item"
                        @click.prevent="getusers(pagination.next_page_url)"
                        :class="{disabled: !pagination.next_page_url}">
                        <a
                            class="page-link" href="#">Следующая</a>
                    </li>
                </ul>
            </nav>

            <v-dialog
                light
                v-model="dialog"
                max-width="800px"
            >


                <form @submit.prevent="adduser">
                    <div class="form-group row">
                        <div class="col">
                            <input id="name" type="text"
                                   class="form-control" name="name"
                                   v-model="user.name" autocomplete="name" autofocus>
                            <small class="form-text text-muted">Имя</small>
                        </div>
                        <div class="col">
                            <input id="surname" type="text"
                                   class="form-control" v-model="user.surname" name="surname"
                                   autocomplete="surname" autofocus>
                            <small class="form-text text-muted">Фамилия</small>
                        </div>
                    </div>

                    <div class="form-group row">

                        <div class="col">
                            <input id="phone" type="tel"
                                   class="form-control" name="phone"
                                   v-model="user.phone" autocomplete="phone" autofocus>
                            <small class="form-text text-muted">Телефон</small>
                        </div>
                        <div class="col">
                            <input id="email" type="email"
                                   class="form-control" name="email"
                                   v-model="user.email" autocomplete="email">
                            <small class="form-text text-muted">Email</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-6">
                            <select v-model="user.vacant_id" name="vacant" id="vacant" class="form-control">
                                <option class="form-control" value="" >Выберите должность</option>
                                <option class="form-control" v-for="(item, key, index) in vacants" :key="index" :value="key">{{item}}</option>
                            </select>
                            <small class="form-text text-muted">Должность</small>
                        </div>
                        <div class="col-md-6">
                            <select v-model="user.location_id" name="location" id="location" class="form-control">
                                <option value="">Выберите локацию</option>
                                <option v-for="(item, key, index) in locations" :key="index" :value="key">{{item}}</option>
                            </select>
                            <small class="form-text text-muted">Локация</small>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-6">
                            <input id="date_birth" type="date"
                                   class="form-control"
                                   name="date_birth"
                                   v-model="user.date_birth" autocomplete="name" autofocus>
                            <small class="form-text text-muted">Дата рожденья</small>
                            <div @click="disableGoogle(user.id)" v-if="edit && user.gauth == 1" class="btn btn-primary mt-2">Отключить 2FA</div>
                        </div>
                        <div v-if="!edit" class="col-6">
                            <input id="password" type="password"
                                   class="form-control" name="password"
                                   autocomplete="new-password" v-model="user.password">
                            <small class="form-text text-muted">Пароль</small>
                        </div>
                        <div v-else class="col-6">
                            <div v-for="(role,i) in roles" :key="i" class="form-check ml-3">
                                <input v-model="user.roles" class="form-check-input" :id="role" type="checkbox"
                                       :value="role">
                                <label :for="role" class="form-check-label">{{role}}</label>
                            </div>
                            <small class="form-text text-muted">Роли</small>
                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <div class="col-md-6 offset-md-4">
                            <button v-if="edit" type="submit" class="btn btn-primary">Изменить</button>
                            <button v-else type="submit" class="btn btn-primary">Добавить</button>
                        </div>
                    </div>
                </form>
            </v-dialog>
        </div>
    </div>
        <div v-else>
            <h3 class="text-center">У вас нет доступа на данную страницу</h3>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: "users",
        data() {
            return {
                users: [],
                vacants: [],
                locations: [],
                roles: [],
                user: {
                    id: '',
                    name:'',
                    surname: '',
                    phone: '',
                    location_id: '',
                    vacant_id: '',
                    date_birth: '',
                    email: '',
                    active: '',
                    roles: [],
                    gauth: ''
                },
                dialog: false,
                edit: false,
                pagination: {},
            }
        },computed: {
            ...mapGetters(['allPermissions', 'getPermission']),
        }
        ,
        methods: {
            ...mapActions(['getpermissions']),
            getusers(page_url) {
                if (!page_url) {
                    page_url = '/api/users?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                } else {
                    page_url = page_url + '&api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'
                }
                axios.get(page_url)
                    .then(res => {
                        this.users = res.data.Users.data
                        this.makePagination(res.data.Users)
                        let vacants = {};
                        for (let item of res.data.Vacants) {
                            vacants[item.id] = item.title;
                        }
                        this.vacants = vacants;
                        let locations = {};
                        for (let item of res.data.Locations) {
                            locations[item.id] = item.title;
                        }
                        this.locations = locations
                        let roles = {};
                        for (let item of res.data.Roles) {
                            roles[item.id] = item.name;
                        }
                        this.roles = roles;
                    })

            },
            deleteuser(id) {
                axios
                    .delete(`/api/users/${id}`, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                .then(()=>{
                    this.getusers()
                })
            },
            adduser() {
                if (this.edit === false) {
                    axios
                        .post(`/api/users`, {
                            id: this.user.id,
                            name: this.user.name,
                            surname: this.user.surname,
                            location_id: this.user.location_id,
                            vacant_id: this.user.vacant_id,
                            phone: this.user.phone,
                            date_birth: this.user.date_birth,
                            email: this.user.email,
                            active: this.user.active,
                            password: this.user.password

                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.user.id = ''
                            this.user.name = ''
                            this.user.surname = ''
                            this.user.phone = ''
                            this.user.location_id = ''
                            this.user.vacant_id = ''
                            this.user.date_birth = ''
                            this.user.email = ''
                            this.user.active = ''
                            this.user.password = ''
                            this.dialog = false
                            this.getusers()
                        })
                } else {
                    axios
                        .put(`/api/users/${this.user.id}`, {
                            id: this.user.id,
                            name: this.user.name,
                            surname: this.user.surname,
                            location_id: this.user.location_id,
                            vacant_id: this.user.vacant_id,
                            phone: this.user.phone,
                            date_birth: this.user.date_birth,
                            email: this.user.email,
                            active: this.user.active,
                            roles: this.user.roles
                        }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                        .then(res => {
                            this.user.id = ''
                            this.user.name = ''
                            this.user.surname = ''
                            this.user.location_id = ''
                            this.user.phone = ''
                            this.user.vacant_id = ''
                            this.user.date_birth = ''
                            this.user.email = ''
                            this.user.active = ''
                            this.edit = false
                            this.dialog = false
                            this.getusers()
                        })
                }

            },
            edituser(user) {
                this.edit = true
                this.user.id = user.id
                axios.get(`/api/users/roles/${user.id}?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP`)
                    .then(res => {
                        this.user.roles = res.data
                    })
                axios.get(`/api/users/2fa/${user.id}?api_token=vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP`)
                .then(res => {
                    this.user.gauth = res.data
                })
                this.user.name = user.name
                this.user.surname = user.surname
                this.user.phone = user.phone
                this.user.location_id = user.location_id
                this.user.vacant_id = user.vacant_id
                this.user.date_birth = user.date_birth
                this.user.email = user.email
                this.user.active = user.active
            },
            noedit() {
                this.edit = false
                this.user.id = ''
                this.user.name = ''
                this.user.surname = ''
                this.user.phone = ''
                this.user.location_id = ''
                this.user.vacant_id = ''
                this.user.date_birth = ''
                this.user.email = ''
                this.user.active = ''
                this.user.password = ''
                this.user.roles = []
                this.gauth = true;
                this.user.active = true;
            },
            makePagination(response) {
                let pagination = {
                    current_page: response.current_page,
                    last_page: response.last_page,
                    prev_page_url: response.prev_page_url,
                    next_page_url: response.next_page_url
                }
                this.pagination = pagination
            },
            toggleUser(id){
                axios
                    .post(`/api/users/toggle`,{
                        id: id,
                    }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                .then(() =>{
                    this.getusers()
                })
            },
            disableGoogle(id){
                axios
                .post(`/api/users/2fa`,{
                    id: id,
                }, {headers: {'Authorization': 'Bearer vNpexLAk2FC7uxM85ScsvqLWjn6b0GBy9lnUxuqwaNCsPSDeKNIRADCKmCHGT1mATTSYAHBPA97sx1FP'}})
                    .then(() =>{
                        this.getusers()
                        this.user.gauth = 0;
                    })
            }
        },
        mounted() {
            this.getusers()
            this.getpermissions()
        }
    }
</script>

<style scoped>
    form {
        background: #fff;
        padding: 12px;
        margin: 0;
    }

    .btn-primary {
        min-width: 60px;
    }

    .fa-plus {
        font-size: 30px;
    }
</style>
