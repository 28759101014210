<template>
    <div>

            <div class="notification">
                <v-badge
                    v-if="notifications.length > 0"
                    v-popover="{name: 'notifications'}"
                    :content="notifications.length"
                    color="red"
                    left
                    medium
                    overlap
                >
                    <v-icon :style="style">fa-bell</v-icon>
                </v-badge>
                <v-icon v-popover="{name: 'notifications'}"
                        v-else
                        medium
                        :style="style">fa-bell
                </v-icon>
            </div>

            <popover name="notifications" :width="width">
                <div class="container">
                    <div class="head">
                        Уведомления
                    </div>
                    <hr>
                    <div v-if="notifications.length == 0" class="nottextempty mt-2">
                        У вас нет непрочитанных уведомлений!
                    </div>
                    <transition-group name="fade">
                    <div v-for="notification in notifications" :key="notification.id" class="mt-2">
                        <div class="topnot d-flex justify-content-between">
                            <div class="date">
                                {{notification.data.date}}
                            </div>
                            <i @click="markAsRead(notification.id)" class="fa fa-trash-o ml-3" aria-hidden="true"
                               title="Прочитать уведомление"></i>
                        </div>
                        <a class="nottext" :href="notification.data.url">{{ notification.data.text}}</a>
                    </div>
                    </transition-group>
                    <hr>
                    <a v-if="notifications.length != 0" class="nottext mt-2" @click.prevent="readAll">Прочитать все уведомления</a>
                </div>
            </popover>

    </div>

</template>

<script>
    import {eventEmitter} from "../../app";

    export default {
        name: "notification",
        data: function () {
            return {
                style: 'color: white',
                notifications: [],
                isHide: true,
                width: 300
            }
        },
        methods: {
            getNotifications() {
                axios.get(`/vue/getnotifications?vue=q9F~<J`).then(
                    (response) => {
                        this.notifications = response.data;
                        if (response.data.length != 0) {
                            this.style = 'color: white';
                        } else {
                            this.style = 'color: white'
                        }

                    }
                )
            },
            markAsRead(id) {
                axios.get(`/vue/readNotification/${id}?vue=q9F~<J`)
                .then(()=> {
                    this.getNotifications();
                });
            },
            readAll(){
                axios.get('/vue/readAllNotifications')
                    .then(()=> {
                        this.getNotifications();
                    });
            }
        },
        mounted() {
            this.getNotifications()
            eventEmitter.$on('notification', count => {
                this.getNotifications()
            })
        }

    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }
    [data-popover] {
        background-color: white;
        line-height: 1.5;
        margin: 5px;
    }
    .vue-popover.dropdown-position-bottom:before{
        display: none;
    }
    .alert-info{
        width: 100%;
    }
    .head{
        background-color: transparent;
        color: rgba(51,51,51,1.0);
        font-family: 'Roboto', Helvetica, Arial, serif;
        font-size: 14.0px;
        font-style: normal;
        font-weight: 500;
    }
    .nottext{
        color: rgba(51,51,51,1.0);
        font-family: 'Roboto', Helvetica, Arial, serif;
        font-size: 11.0px;
        font-style: normal;
        font-weight: 500;
    }
    .nottextempty{
        color: rgba(51,51,51,1.0);
        font-family: 'Roboto', Helvetica, Arial, serif;
        font-size: 12.0px;
        font-style: normal;
        font-weight: 500;
    }
    .date{
        color: rgba(115,115,115,1.0);
        font-family: 'Roboto', Helvetica, Arial, serif;
        font-size: 11.0px;
        font-style: normal;
        font-weight: 500;
    }
</style>
