import VueRouter from 'vue-router'
import Home from './components/Pages/Profile/index'
import Myissues from './components/Pages/Profile/myissues'
import All from './components/Pages/Profile/all'
import Categories from './components/Pages/Profile/Categories'
import Articles from './components/Pages/Profile/Articles'
import Vacants from "./components/Pages/admin/Vacants";
import Locations from "./components/Pages/admin/Locations";
import IssueTopics from "./components/Pages/admin/IssueTopics";
import Roles from "./components/Pages/admin/Roles";
import Permissions from "./components/Pages/admin/Permissions";
import Lessons from "./components/Pages/admin/Lessons";
import Pages from "./components/Pages/admin/Pages";
import Category from "./components/Pages/admin/Category";
import AdArticles from "./components/Pages/admin/Articles"
import News from "./components/Pages/admin/News";
import Users from "./components/Pages/admin/Users";
import Index from "./components/Pages/admin/Index";
import SingleIssue from './components/Pages/Profile/singleissue'
import Best from './components/Pages/admin/Best'
import AllNews from "./components/Pages/AllNews";
import SingleNews from "./components/Pages/SingleNews";


export default new VueRouter({
    routes: [
        {
            path:'/news',
            component: AllNews,
            name: 'allnews'
        } ,
        {
            path:'/news/:id',
            component: SingleNews,
            name: 'singlenews'
        } ,
        {
            path:'/profile/myissues',
            component: Myissues,
            name: 'myissues'
        } ,
        {
            path:'/profile/issue/:id',
            component: SingleIssue,
            name: 'singleissue'
        } ,
        {
            path:'/profile/articles',
            component: Categories,
            name: 'categories',
            props: {q: ''}
        } ,
        {
            path:'/profile/articles/:category',
            component: Articles,
            name: 'articles'
        } ,
        {
            path:'/profile/all',
            component: All,
            name: 'all'
        } ,
        {
            path:'/profile/:id',
            component: Home,
            name: 'profile'
        } ,
        {
            path:'/admin',
            component: Index,
            name: 'admin.index'
        } ,
        {
            path:'/admin/vacants',
            component: Vacants,
            name: 'admin.vacants'
        } ,
        {
            path:'/admin/best',
            component: Best,
            name: 'admin.best'
        } ,
        {
            path:'/admin/locations',
            component: Locations,
            name: 'admin.locations'
        } ,
        {
            path:'/admin/topics',
            component: IssueTopics,
            name: 'admin.topics'
        } ,
        {
            path:'/admin/pages',
            component: Pages,
            name: 'admin.pages'
        } ,
        {
            path:'/admin/roles',
            component: Roles,
            name: 'admin.roles'
        } ,
        {
            path:'/admin/permissions',
            component: Permissions,
            name: 'admin.permissions'
        } ,
        {
            path:'/admin/lessons',
            component: Lessons,
            name: 'admin.lessons'
        } ,
        {
            path:'/admin/categories',
            component: Category,
            name: 'admin.categories'
        } ,
        {
            path:'/admin/articles',
            component: AdArticles,
            name: 'admin.articles'
        } ,
        {
            path:'/admin/news',
            component: News,
            name: 'admin.news'
        } ,
        {
            path:'/admin/users',
            component: Users,
            name: 'admin.users'
        } ,
    ],
    mode: 'history'
})

