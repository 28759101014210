<template>
    <div>
        <div class="mt-2">
            <h3>Рейтинг статьи</h3>
            <div v-if="liked == 0">
                <p>Оценить статью</p>
                <fa-rating :glyph="thumbsUp"
                           :incriment="1"
                           active-color="#5e498c"
                           :max-rating="5"
                           :item-size="25"
                           :spacing="7"
                           v-model="rating">

                </fa-rating>
                <div>Ваша оценка: {{rating}}</div>
                <button @click="setRating" class="btn btn-primary">Оценить статью</button>
            </div>
            <hr v-if="liked == 0">
            <div class="mt-2">
                <fa-rating :glyph="thumbsUp"
                           :inline="true"
                           :increment="0.1"
                           active-color="#5e498c"
                           :max-rating="5"
                           :item-size="25"
                           :spacing="7"
                           :show-rating="false"
                           :read-only="true"
                           v-model="totalrate"
                >

                </fa-rating>

                <div class="d-flex justify-content-between mt-2">
                    <p>Всего оценок: {{totaluser}} <br>Средняя оценка: {{totalrate}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {FaRating} from 'vue-rate-it';
    import ThumbsUp from 'vue-rate-it/glyphs/star';

    export default {
        components: {
            FaRating
        },
        name: 'NewsRating',
        created() {
            // register the icon
            this.thumbsUp = ThumbsUp
        },
        mounted() {
            this.getLiked()
            this.getRating()
        },
        props: ['id'],
        data() {
            return {
                rating: 3,
                thumbsUp: '',
                liked: 0,
                totalrate: 0,
                totaluser: 0,
            }
        },
        methods: {
            getRating() {
                axios.get(`/article/rate/${this.id}`)
                    .then(res => {
                        let mydata = res.data;
                        this.totaluser = mydata.length;
                        let sum = 0;
                        for (let i = 0; i < mydata.length; i++) {
                            sum += parseFloat(mydata[i]['rating'])
                        }
                        let avg = sum / mydata.length;
                        this.totalrate = parseFloat(avg.toFixed(1))
                        if(mydata.length == 0){
                            this.totalrate = 0;
                        }
                    }).catch(err => {
                    console.log(err)
                })
            },
            getLiked() {
                axios.get(`/article/rate/get/${this.id}`)
                    .then(res => this.liked = res.data)
            }
            ,
            setRating() {
                let data = new FormData();
                data.append('rating', this.rating);
                axios.post(`/article/rate/${this.id}`, data)
                    .catch((error) => {
                            alert('Что то пошло не так');
                        }
                    )
                this.getLiked()
                this.getRating()
            }
        }
    }


</script>

<style scoped>
    .col-md-5{
        padding-left: 0;
    }
</style>
