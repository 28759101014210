<template>
    <div>
        <form @submit.prevent="addIssue">
            <validation-errors v-if="validationErrors"
                               :errors="validationErrors">

            </validation-errors>
            <div class="form-group row">
                        <textarea v-model="newissue.body" name="body" id="body" cols="30" rows="10"
                                  class="form-control"></textarea>
            </div>
            <div class="form-group row mb-0">
                    <button type="submit" class="btn btn-primary">
                        Задать вопрос по статье
                    </button>
            </div>
        </form>
<!--        <button class="btn btn-primary mt-4 float-right" @click="dialog = true">Задать вопрос по статье</button>-->

        <v-dialog
            v-model="dialog"
            max-width="800px">

        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "ArticleQuestion",
        props: ['article_id'],
        data() {
            return {
                dialog: false,
                newissue: {
                    topic_id: 4,
                    body: ''
                },
                validationErrors: '',
            }
        },
        methods: {
            addIssue() {
                axios
                    .post(`/issue/new`, {
                        topic: this.newissue.topic_id,
                        body: this.newissue.body,
                        article_id: this.article_id
                    })
                    .then(() => {
                        this.newissue.body = ''
                        this.validationErrors = ''
                        this.dialog = false
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.validationErrors = error.response.data.errors
                        }
                    })
            },
        }
    }
</script>

<style scoped>
    form {
        background: #fff;
        padding: 16px;
        margin: 0;
    }
</style>
